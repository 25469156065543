import {publicRequest, userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
  logout,
  loginFailure,
  loginStart,
  loginSuccess,
  registerStart,
  registerSuccess,
  registerFailure,
  verifyFailure,
  verifyStart,
  verifySuccess,
  forgotFailure,
  forgotStart,
  forgotSuccess,
} from "../authRedux";

export const registerFunc = async (dispatch, user)=>{
  // dispatch(registerStart());
  dispatch(loginStart());
  try{
    const res = await publicRequest.post("/user/auth/register",user)
    // dispatch(registerSuccess(res.data))
    dispatch(loginSuccess(res.data))
     
    if(res.data.success){
      localStorage.setItem(
          "JamboloUsertoken",
          res.data.data.token
      );
      localStorage.setItem('user', JSON.stringify(res.data.data))
        Notification.success(res.data.message);
        // window.location.href = "/dashboard/index"
        return res.data.message;
    }
  }catch (err){
    dispatch(registerFailure())
    Notification.error(err.response.data.message);
  }
};

export const loginFunc = async (dispatch, user)=>{
  dispatch(loginStart());
  try{
   
    const res = await publicRequest.post("/user/auth/login",user)
    dispatch(loginSuccess(res.data))
    
    if(res.data.success){
      localStorage.setItem(
          "JamboloUsertoken",
          res.data.data.token
      );
      localStorage.setItem('user', JSON.stringify(res.data.data))
      if(res.data.data.token){
        Notification.success(res.data.message);
       return res.data.message;
      }
    }
  }catch (err){
    dispatch(loginFailure())
    Notification.error(err.response.data.message);
  }
};

export const verifyEmail = async (dispatch, expires, hash, id, signature)=>{
  dispatch(verifyStart());
  try{
    const res = await publicRequest.get(`/user/auth/email/verify?expires=${expires}&hash=${hash}&id=${id}&signature=${signature}`)
    dispatch(verifySuccess(res.data))

        Notification.success(res.data.message);
  }catch (err){
    dispatch(verifyFailure())
    Notification.error(err.response.data.message);
  }
};

export const forgotPassword = async (dispatch, data)=>{
  dispatch(verifyStart());
  try{
    const res = await publicRequest.post(`/user/auth/password/forgot`, data)
    dispatch(verifySuccess(res.data))

    Notification.success(res.data.message);
  }catch (err){
    dispatch(verifyFailure())
    Notification.error(err.response.data.message);
  }
};

// Logout user
export const logoutFunc = async (dispatch) => {
  localStorage.clear();
  window.location.reload();
}




