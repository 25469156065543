import { createSlice } from "@reduxjs/toolkit";

export const genericSlice = createSlice({
    name: "generic",
    initialState: {
        order_data: [],
        payout_data: [],
        all_orders:[],
        single_order:null,
        isFetching: false,
        error: false,
    },
    reducers: {
        //GET ALL
        addOrderStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        addOrderSuccess: (state, action) => {
            state.isFetching = false;
            state.order_data = action.payload;
        },
        addOrderFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //GET SEARCH
        addPayoutStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        addPayoutSuccess: (state, action) => {
            state.isFetching = false;
            state.payout_data = action.payload;
        },
        addPayoutFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //GET ALL
        getOrdersStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getOrdersSuccess: (state, action) => {
            state.isFetching = false;
            state.all_orders = action.payload;
        },
        getOrdersFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //GET Single Order
        getSingleOrderStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getSingleOrderSuccess: (state, action) => {
            state.isFetching = false;
            state.order = action.payload;
        },
        getSingleOrderFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const {
    addPayoutStart,
    addPayoutSuccess,
    addPayoutFailure,
    addOrderStart,
    addOrderSuccess,
    addOrderFailure,
    getSingleOrderFailure,
    getSingleOrderStart,
    getSingleOrderSuccess,
    getOrdersFailure,
    getOrdersStart,
    getOrdersSuccess,
} = genericSlice.actions;

export default genericSlice.reducer;
