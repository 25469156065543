import React from "react";
import RequestForm from '../molecules/RequestForm'

const MakeaRequest = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center align-items-center">
          <div className="col-md-8">
            <div className="card" style={{ height: 'auto', padding: 30 }}>
              <div>
                <h4 className='text-center fw-bold'>Request form</h4>
                <p className='text-center'>fill in the blank spaces correctly</p>
              </div>
              <div>
                  <RequestForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakeaRequest;
