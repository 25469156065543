import React, {useEffect, useState} from "react";
import ChangePasswordForm from '../molecules/ChangePasswordForm'
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import Button from "../Buttons";

const ChangePassword = ({updatePassword}) => {
    const dispatch = useDispatch()
    const [currentPassword, setCurrentPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const { isFetching } = useSelector(state => state.profile)

    const onsubmit = (e) => {
        e.preventDefault()
        updatePassword(dispatch,
            { current_password: currentPassword,
                password: password,
                confirm_password: confirmPassword
            }
        )
    }

    return (
    <>
      <div
        className="modal fade"
        id="changepasswordModal"
        tabindex={-1}
        aria-labelledby="changepasswordModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
                <form onSubmit={onsubmit}>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <label htmlFor="old password">Enter old Password</label>
                            <input
                                type="text"
                                name="current_password"
                                placeholder="*******"
                                onChange={(e) => setCurrentPassword(e.target.value)}
                                className="form-control" />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <label htmlFor="new password">Enter new password</label>
                            <input
                                type="password"
                                name="password"
                                placeholder="*******"
                                onChange={(e) => setPassword(e.target.value)}
                                className="form-control" />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <label htmlFor="confirm password">Confirm password</label>
                            <input
                                type="password"
                                name="confirm_password"
                                placeholder="********"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className="form-control" />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <Button
                                loading={isFetching}
                                className="btn btn-danger text-white fw-bold"
                                style={{ width: "100%" }}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </form>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
