// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import { BrowserRouter } from "react-router-dom";
// import reportWebVitals from "./reportWebVitals";
//
// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>
// );
//
// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import "./index.css";
import { Provider } from "react-redux";
import {store, persistor} from "./redux/store";
import { SnackbarProvider } from 'notistack';
import Notification from './utils/notification';
import { PersistGate } from 'redux-persist/integration/react'
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
        <React.StrictMode>
             <BrowserRouter>
        <Provider store={store}>
            <SnackbarProvider
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'top',
                }}
                ref={Notification.reference}
                action={key => <div style={{ cursor: 'pointer' }} onClick={() => Notification.close(key)}>CLOSE</div>}
            >
                <PersistGate loading={null} persistor={persistor}>

                    <App />
                </PersistGate>

            </SnackbarProvider>
        </Provider>
             </BrowserRouter>
    </React.StrictMode>
);
reportWebVitals();

