import {publicRequest, userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getSingleCartFailure,
    getSingleCartStart,
    getSingleCartSuccess,
    getCartFailure,
    getCartStart,
    getCartSuccess,
    deleteCartFailure,
    deleteCartStart,
    deleteCartSuccess,
    updateCartFailure,
    updateCartStart,
    updateCartSuccess,
    addCartFailure,
    addCartStart,
    addCartSuccess,
    walletCheckoutFailure,
    walletCheckoutStart,
    walletCheckoutSuccess,
    paystackCheckoutFailure,
    paystackCheckoutStart,
    paystackCheckoutSuccess,
} from "../cartRedux";

//

export const storeCart = async (dispatch, Cart) => {
    dispatch(addCartStart());
    try {
        // const res = await userRequest.post(`/Carts`, Cart);
        const res = await userRequest.post(`user/carts`, Cart);
        dispatch(addCartSuccess(res.data.data));
        Notification.success(res.data.message);
        window.location.reload();
    } catch (err) {
        dispatch(addCartFailure());
        console.log(err)
        Notification.error(err.response.data.message);
    }
};

export const walletCheckout = async (dispatch, data) => {
    dispatch(walletCheckoutStart());
    try {
        const res = await userRequest.post(`user/carts/wallet-checkout`, data);
        // console.log("post data", res.data.data)
        dispatch(walletCheckoutSuccess(res.data.data));
        Notification.success(res.data.message);
        window.location.href = "/dashboard/index"
    } catch (err) {
        dispatch(walletCheckoutFailure());
        console.log(err)
        Notification.error(err.response.data.message);
    }
};

export const paystackCheckout = async (dispatch, cartId) => {
    dispatch(paystackCheckoutStart());
    try {
        const res = await userRequest.post(`user/carts/paystack-checkout`, cartId);
        // console.log("post data", res.data.data)
        dispatch(paystackCheckoutSuccess(res.data.data));
        Notification.success(res.data.message);        
        window.location.replace(res.data.data.authorization_url);        
    } catch (err) {
        dispatch(paystackCheckoutFailure());
        console.log(err)
        Notification.error(err.response.data.message);
    }
};

export const getAllCarts = async (dispatch) => {
    dispatch(getCartStart());
    try {
        const res = await userRequest.get("/user/carts?include=product");
        dispatch(getCartSuccess(res.data.data.cart));
        // Notification.success(res.data.message);
        return res.data.data.cart;
    } catch (err) {
        dispatch(getCartFailure());
        Notification.error(err.response.data.message);
    }
};

export const getSingleCart = async (id, dispatch) => {
    // alert(5)
    dispatch(getSingleCartStart());
    try {
        const res = await userRequest.get(`/user/carts/${id}`);
        dispatch(getSingleCartSuccess(res.data.data.cart));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getSingleCartFailure());
        Notification.error(err.response.data.message);
    }
};

export const updateCart = async (id, Cart, dispatch) => {
    // alert(5)
    dispatch(updateCartStart());
    try {
        const res = await userRequest.put(`/user/carts/${id}`, Cart);
        dispatch(updateCartSuccess(res.data.data));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(updateCartFailure());
        Notification.error(err.response.data.message);
    }
};

export const deleteCartFunc = async (id, dispatch) => {
    dispatch(deleteCartStart());
    try {
        const res = await userRequest.delete(`/user/carts/${id}`);
        dispatch(deleteCartSuccess(id));
        Notification.success(res.data.message);
        window.location.reload();
    } catch (err) {
        dispatch(deleteCartFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePageChange = async (pageNum, perPage, dispatch) => {
    dispatch(getCartStart());
    try {
        const res = await userRequest.get(`/user/Carts?page=${pageNum}&limit=${perPage}`);
        dispatch(getCartSuccess(res.data.data.carts));
    } catch (err) {
        dispatch(getCartFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePerRowsChange = async (page, perPage, dispatch) => {
    dispatch(getCartStart());
    try {
        const res = await userRequest.get(`/user/Carts?page=${page}&limit=${perPage}`);
        dispatch(getCartSuccess(res.data.data.carts));
    } catch (err) {
        dispatch(getCartFailure());
        Notification.error(err.response.data.message);
    }
};
