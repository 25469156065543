import React from "react";

import { Link, Outlet, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGauge,
    faUser,
    faSignOut,
    faClipboard,
    faGlobe,
    faMoneyBillTransfer
} from "@fortawesome/free-solid-svg-icons";
import Logo from "../assets/brand/jambolo-logo-main.png";
import LogoWhite from "../assets/brand/jambolo-logo-w.png";
import { logoutFunc } from "../../redux/ApiCalls/authCalls";
import { useDispatch } from "react-redux";



const style = {
    link: {
        textDecoration: "none",
        color: "#fff",
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1,
        padding: 10,
    },

    linkSec: {
        textDecoration: "none",
        color: "#dc3545",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 1,
        padding: 10,
    },
};


const Sidebar = () => {
    const dispatch = useDispatch()
    const location = useLocation();
    const page = location.pathname.split('/')[2];
    return (
        <>
            <div
                className="bg-danger text-dark"
                tabIndex="-1"
                id="offcanvasExample"
                aria-labelledby="offcanvasExampleLabel"
                style={{ height: "100vh", width: "17%", position: "fixed" }}
            >
                <div className="offcanvas-header">
                    <a className="navbar-brand er mx-3" href="/">
                        <img style={{ height: "40px", objectFit: "cover" }} src={Logo} alt="Jambolo" className="dark-logo" />
                        <img style={{ height: "40px", objectFit: "cover" }} src={LogoWhite} alt="Jambolo" className="main-logo" />
                    </a>
                </div>
                <div className="offcanvas-body-style">
                    <ul style={{ listStyleType: "none", marginTop: "70px" }}>
                        {/*<li className="mb-2 ">*/}

                        <li className={"mt-3 mb-4" + (page === "index" ? " active-sidebar" : "")}>
                            <Link to="index" style={(page === "index" ? style.linkSec : style.link)}>
                                <FontAwesomeIcon
                                    icon={faGauge}
                                    color={(page === "index" ? "#dc3545" : "#FFFFFF")}
                                    className="mx-2"
                                />{" "}
                                Dashboard
                            </Link>
                        </li>

                        <li className={"mtt-2rm mb-4" + (page === "wallet" ? " active-sidebar" : "")}>
                            <Link to="wallet" style={(page === "wallet" ? style.linkSec : style.link)}>
                                <FontAwesomeIcon
                                    icon={faMoneyBillTransfer}
                                    color={(page === "wallet" ? "#dc3545" : "#FFFFFF")}
                                    className="mx-2"
                                />{" "}
                                Wallet
                            </Link>
                        </li>

                        <li className={"mtt-2rm mb-4" + (page === "transactions" ? " active-sidebar" : "")}>
                            <Link to="transactions" style={(page === "transactions" ? style.linkSec : style.link)}>
                                <FontAwesomeIcon
                                    icon={faClipboard}
                                    color={(page === "transactions" ? "#dc3545" : "#FFFFFF")}
                                    className="mx-2"
                                />{" "}
                                Transactions
                            </Link>
                        </li> 

                        {/* <li className={"mtt-2rm mb-4" + (page === "requests" ? " active-sidebar" : "")}>
                            <Link to="requests" style={(page === "requests" ? style.linkSec : style.link)}>
                                <FontAwesomeIcon
                                    icon={faClipboard}
                                    color={(page === "requests" ? "#dc3545" : "#FFFFFF")}
                                    className="mx-2"
                                />{" "}
                                Request
                            </Link>
                        </li> */}

                        <li className={"mtt-2rm mb-4" + (page === "market" ? " active-sidebar" : "")}>
                            <Link to="market" style={(page === "market" ? style.linkSec : style.link)}>
                                <FontAwesomeIcon
                                    icon={faGlobe}
                                    color={(page === "market" ? "#dc3545" : "#FFFFFF")}
                                    className="mx-2"
                                />{" "}
                                Market Place
                            </Link>
                        </li>


                        <li className={"mtt-2rm mb-4" + (page === "profile" ? " active-sidebar" : "")}>
                            <Link to="profile" style={(page === "profile" ? style.linkSec : style.link)}>
                                <FontAwesomeIcon
                                    icon={faUser}
                                    color={(page === "profile" ? "#dc3545" : "#FFFFFF")}
                                    className="mx-2"
                                />{" "}
                                Profile
                            </Link>
                        </li>


                        <li style={{ bottom: 0, position: "absolute", marginBottom: "40px" }}
                            onClick={() => logoutFunc(dispatch)}
                        > 
                            <Link to="#" style={style.link}>
                                <FontAwesomeIcon
                                    icon={faSignOut}
                                    color={"#FFFFFF"}
                                    className="mx-2"
                                />{" "}
                                Sign out
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};
export default Sidebar;
