import React, { useEffect, useState } from "react";
import CustomLoader from "../../components/CustomLoader";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import 'react-data-table-component-extensions/dist/index.css';


import { useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
// import NumberFormat from "react-number-format";
import { getTransaction,handlePageChange, handlePerRowsChange } from "../../redux/ApiCalls/transactionCalls";
import { NumberFormatBase } from "react-number-format";


const Transaction = () => {
    const dispatch = useDispatch();
    const [artisans, setArtisans] = useState(null);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState(null);
    const filterPhone = (e) => {
        setBusiness(e);
        getTransaction(dispatch, '',business)
    }


    const fetchTransactions = async (pageNum) => {
		setLoading(true);

        getTransaction(dispatch, '',pageNum).then(res=>{

            setData(res.data);
            setTotalRows(res.total);
            setLoading(false);
        });
	};
    
   
    useEffect(() => {
        fetchTransactions(1);
    }, [dispatch])

    const [columns, setColumns] = useState([
       
        {
            name: 'Date Created',
            
            sortable: true,
            cell: row => row.created_at ?
                <div>
                    <Moment format="D MMM YYYY">{row.created_at}</Moment>
                </div>
                :
                <div>

                </div>
        },
        {
            name: 'Payment Type',
            cell: row => row.payment_method,
            sortable: true,
        },
        {
            name: 'Amount',
            cell: row => row.amount,
            sortable: true,
        },
        {
            name: 'Payment Reference',
            
            sortable: true,
            cell: row => row.reference
        },        
        {
            name: 'Purpose',            
            sortable: true,
            cell: row => row.payment_purpose
        },  
             
    ],
    )
    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
                minWidth: '10px'
            },
        },
        headCells: {
            style: {
                paddingLeft: '2px', // override the cell padding for head cells
                paddingRight: '2px',
            },
        },
        // cells: {
        //     style: {
        //         paddingLeft: '8px', // override the cell padding for data cells
        //         paddingRight: '8px',
        //         width: '15px'
        //     },
        // },
    };
    
    const [business, setBusiness] = useState()
    
  
    const handlePageChanges = (pageNum, perPage) => {
        fetchTransactions(pageNum,perPage);
        //handlePageChange(pageNum, 15, dispatch);
    }

    const handlePerRowsChanges = (perPage, page) => {
        //handlePerRowsChange(page, perPage, dispatch);
    }


    // const artisans = useSelector((state) => state.artisans.artisans);
    
    const tableData = { columns, data };
    return (
        <>
            <div id="layoutSidenav">

                
                <div id="layoutSidenav_content">
                     <main>
                        <div className="container-fluid px-4">
                            <div className="d-flex justify-content-between align-items-baseline">
                                <h5 className="mt-4">Transaction History</h5>
                                
                            </div>

                            <div className="card mb-4">
                                <div className="card-body">
                                    
                                    <div className="form-floating" style={{ width: "20%", float: "right", marginBottom: "10px", paddingRight:"5px" }}>
                                        <input
                                            className="form-control"
                                            type="text"
                                            onChange={(e) => filterPhone(e.target.value)}/>
                                            
                                        <label htmlFor="inputLastName">Filter</label>
                                    </div>
                                    
                                    {/* <DataTableExtensions {...tableData}> */}
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            noHeader
                                            defaultSortAsc={false}
                                            highlightOnHover
                                            // progressPending={orderLoading}
                                            progressComponent={<CustomLoader/>}
                                            pagination={true}
                                            paginationServer={true}
                                            paginationResetDefaultPage={true}
                                            export={false}
                                            filterHidden={true}
                                            print={false}
                                            paginationPerPage={15}
                                            paginationTotalRows={totalRows}
                                            paginationRowsPerPageOptions={[15, 30, 45, 60, 75]}
                                            onChangeRowsPerPage={handlePerRowsChanges}
                                            onChangePage={handlePageChanges}
                                            // customStyles={customStyles}
                                        />
                                    {/* </DataTableExtensions> */}
                                </div>
                            </div>
                        </div>
                    </main>

                    

                    
                </div>


            </div>

        </>
    )
}
export default Transaction;