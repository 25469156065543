import React from "react";
import MarketTable from "../molecules/MarketTable";

const MarketOrder = () => {
  return (
    <>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{padding: 30}}>
              <MarketTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketOrder;
