import { createSlice } from "@reduxjs/toolkit";

export const walletSlice = createSlice({
    name: "wallet",
    initialState: {
        wallet: null,
        wallets: [],
        isFetching: false,
        error: false,
    },
    reducers: {
        //GET ALL
        getWalletStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getWalletSuccess: (state, action) => {
            state.isFetching = false;
            state.wallet = action.payload;
        },
        getWalletFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //GET Single Wallet
        getSingleWalletStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getSingleWalletSuccess: (state, action) => {
            state.isFetching = false;
            state.Wallet = action.payload;
        },
        getSingleWalletFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //DELETE
        deleteWalletStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        deleteWalletSuccess: (state, action) => {
            state.isFetching = false;
            // state.Wallets.splice(
            //   state.Wallets.findIndex((item) => item._id === action.payload),
            //   1
            // );
            // state.Wallets = {...state.Wallets};
        },
        deleteWalletFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        updateWalletStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        updateWalletSuccess: (state, action) => {
            state.isFetching = false;
            state.Wallets[
                state.Wallets.findIndex((item) => item._id === action.payload.id)
                ] = action.payload.Wallet;
        },
        updateWalletFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        addWalletStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        addWalletSuccess: (state, action) => {
            state.isFetching = false;
            state.wallets.push(action.payload);
        },
        addWalletFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const {
    getWalletStart,
    getWalletSuccess,
    getWalletFailure,
    getSingleWalletStart,
    getSingleWalletSuccess,
    getSingleWalletFailure,
    deleteWalletStart,
    deleteWalletSuccess,
    deleteWalletFailure,
    updateWalletStart,
    updateWalletSuccess,
    updateWalletFailure,
    addWalletStart,
    addWalletSuccess,
    addWalletFailure,
} = walletSlice.actions;

export default walletSlice.reducer;
