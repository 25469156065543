import { createSlice } from "@reduxjs/toolkit";

export const genericLocationsSlice = createSlice({
    name: "genericLocations",
    initialState: {
        countries: [],
        states: [],
        cities: [],
        isFetching: false,
        error: false,
    },
    reducers: {
        //GET ALL
        getCountriesStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getCountriesSuccess: (state, action) => {
            state.isFetching = false;
            state.countries = action.payload;
        },
        getCountriesFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //GET States
        getStateStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getStateSuccess: (state, action) => {
            state.isFetching = false;
            state.states = action.payload;
        },
        getStateFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //GET Cities
        getCitiesStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getCitiesSuccess: (state, action) => {
            state.isFetching = false;
            state.cities = action.payload;
        },
        getCitiesFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});


export const {
    getStateFailure,
    getStateStart,
    getStateSuccess,
    getCountriesFailure,
    getCountriesStart,
    getCountriesSuccess,
    getCitiesFailure,
    getCitiesStart,
    getCitiesSuccess,
} = genericLocationsSlice.actions;

export default genericLocationsSlice.reducer;
