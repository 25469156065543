import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import Pic from '../assets/wood.jpg'
import HeaderBg from '../assets/market-place.jpg'
import {Navbar} from '../organism/Navbar'
import Footer from '../organism/Footer'
import {InputField} from "../atoms/InputField";
import {Link} from "react-router-dom"
import {getAllProducts} from "../../redux/ApiCalls/productCalls";
import {useDispatch, useSelector} from "react-redux";
import LoginModal from "../organism/LoginModal";


const Marketplace = () => {
	const dispatch = useDispatch()
	const [searchName, setSearchName] = useState("");
	const [products,setProduct] = useState({});
	// const history=userHistory();
	useEffect( () => {
		getAllProducts(dispatch).then(res =>{
			
			if(res=="undefined")
			{

				const element = document.getElementById('exampleModal');			
				element.className='modal fade show d-block';			
			}
			else{
				setProduct(res);
			}
            
           
        })
	}, [dispatch]);

	const searchTrigger =(e)=>{
		getAllProducts(dispatch, e.target.value).then(resp=>{
			setProduct(resp);
		});
	}

	const {isFetching} = useSelector(state=>state?.product);
	// const products = useSelector((state) => state?.product?.products);
  return (
    <>
    <Navbar />
	<section className="page-header with-bg" style={{ backgroundImage: `url(${HeaderBg})` }}>
		<div className="row page-header-row justify-content-center">
			<div className="col-md-8 title-wrap text-center">
				<h2 className="page-title text-white">Market Place</h2>
				<p className="text-white">Discover some of the most popular listing in your area based on reviews and ratings.</p>
			</div>
			<input style={{width:"50%", height:"50px", borderRadius:"4px"}}
						onChange={(e) => searchTrigger(e)}
						name="search" className="form-control width-50 mx-2"
						datalist="true" label="d-none" placeholder="Search..." />

		</div>
	</section>
	<section className="section page-section">
		<div className="container">
			<div className="row">
				{products?.data?.length > 0 ? products?.data?.map((data, index) =>
					<div className="col-md-4" key={data.id}>
						<div className="card list-item">
							<div className="list-image">
								<Link to={`/marketcart/${data?.id}`}>

								{/*<img src={data.featured_image} alt="item image" />*/}
								<img src={data.featured_images.length> 0? data.featured_images[0].original_url: Pic} alt="item image" />
								
								</Link>
								<div className="image-overlay"></div>								
								<span className="list-sector">{data.name}<p>{data.delivery_time_range} {data.delivery_time_desc}</p></span>
							</div>
							<Link to={`/marketcart/${data?.id}`}>

							<div className="list-content">
								<div className="content-wrap">
									<p className="list-title">{data.name}</p>
									<p className="pricing"><span>&#8358;</span> {data.amount}</p>
									<p className="list-desc">{data.description}</p>
									{/*<div className='list-meta'>*/}
									{/*	<div className="meta-item">*/}
									{/*		<span><FontAwesomeIcon icon={faLocationDot} /></span>*/}
									{/*		<span className="mx-2 my-0">{data.location}</span>*/}
									{/*	</div>*/}
									{/*	<div className="meta-item">*/}
									{/*		<span><FontAwesomeIcon icon={faPhone} /></span>*/}
									{/*		<span className="mx-2">{data.mobile}</span>*/}
									{/*	</div>*/}
									{/*</div>*/}
								</div>
							</div>
							<div className="list-footer text-center">
								<button className="btn btn-success">Add To Cart</button>
							</div>
							</Link>
						</div>
					</div>
				)
				:
				// No Records Found !!!
					<h1 style={{textAlign:"center"}}></h1>
				}
			</div>
		</div>
	</section>
    <Footer />
    </>
  );
};

export default Marketplace;
