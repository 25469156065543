import React, { useEffect, useState, useRef } from "react";
import { InputField } from "../atoms/InputField";
import SearchButton from '../atoms/SearchButton';
import { useDispatch, useSelector } from "react-redux";
import { getCategories, search } from "../../redux/ApiCalls/genericCalls";
import { getStates, getCities } from "../../redux/ApiCalls/genericLocationsCalls";
import AutoComplete from "./AutoComplete";

export const SearchForm = ({ handleScrollSwap }) => {
    const [state, setState] = useState({
        category_id: '',
        location: '',
        state_id:'',
        city_id:'',
        lat:'',
        longi:''
    });

    const LocationData=(completeData)=>{
        
        let cus_location = {
            lat: completeData.geometry.location.lat(),
            long: completeData.geometry.location.lng()
            }
        let element=completeData.address_components.length;
        setlongi(cus_location.long);
        setlat(cus_location.lat);
        setCountry(completeData.address_components[element -2].long_name);
        setStIdState(completeData.address_components[element -3].long_name);
        setCity(completeData.address_components[element -4].long_name);
        //setLocation(completeData);
    }
    const [state_id, setStIdState] = useState("") //lagos 2671  abia 2647
    const [city_id, setCity] = useState("") //lagos30984 30983  abia 30692
    const [lat, setlat] = useState("")
    const [longi, setlongi] = useState("")
    const [country_id, setCountry] = useState("160")
    const [stCountry, setCountryState] = useState(true);
    const [stState, setStState] = useState(false);

    const [category_id, setArtisanCategory] = useState("");
    const [location, setLocation] = useState("");
    const [keyword, setKeyword] = useState("");


    const dispatch = useDispatch()

    useEffect(() => {
        getCategories(dispatch)
    }, [])
    const categories = useSelector((state) => state?.categories?.categories);
    const countries = useSelector((state) => state.countries.countries);
    const states = useSelector((state) => state.states.states);
    const cities = useSelector((state) => state.cities.cities);

    if(stCountry){
        getStates(country_id, dispatch);
        setCountryState(false)
    }
    if(stState){
        getCities(state_id, dispatch);
        setStState(false)
    }
    const searchFunc = (e) => {
        e.preventDefault();
        search(dispatch, { category_id, state_id, city_id, keyword, lat, longi })

        setTimeout(() =>
            handleScrollSwap(), 3000);
    };

    return (
        <>
            <form >
                <div className="d-flex justify-content-between searchform-wrap">
                    <select
                        name="artisan_category"
                        onChange={({ target: { value } }) => setArtisanCategory(value)}
                        style={{ width: "35%", paddingTop:"2px",color:"grey", border: "none !important", background: "white",  borderRight: "1px solid #e1e2e3" }}
                        className="form-control mx-2 artisan_category">
                        <option value="">Select Service</option>
                        {categories?.data?.map((data, idx) =>
                            <option key={idx} value={data?.id}>{data?.name}</option>
                        )}
                    </select>
                    
                    {location}
                    <AutoComplete onChange={LocationData}/> 
                    {/* <input
                        name="keyword"
                        // name="location"
                        style={{ width: "25%", border: "none", borderRight: "none" }}
                        onChange={({ target: { value } }) => setKeyword(value)}
                        // onChange={({ target: { value } }) => setLocation(value)}
                        className="form-control width-25 mx-2"
                        placeholder="Type keywords..."
                    /> */}
                    {/*<InputField name="search artisans" classname="form-control mx-2"  datalist="true" label="d-none" placeholder="Local Government Area" />*/}
                    {/*<button type="submit" onClick={searchFunc}>subit</button>*/}
                    <SearchButton handleScrollSwap={handleScrollSwap} searchFunc={searchFunc} />
                </div>
            </form>
        </>
    );
};
