import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone,faBackspace,faAdd,faSubtract } from "@fortawesome/free-solid-svg-icons";
import Pic from '../assets/wood.jpg'
import {Navbar} from '../organism/Navbar'
import Footer from '../organism/Footer'
import {useDispatch, useSelector} from "react-redux";
import {NumericFormat} from "react-number-format";
import {storeCart,updateCart, getAllCarts, deleteCartFunc, walletCheckout, paystackCheckout} from "../../redux/ApiCalls/cartCalls";
import Button from "../Buttons";
import Notification from "../../utils/notification";

const Cart = () => {
    const [itemsCont, setItemsCont] = useState([]);
    const [storeCarts, setStoreCart] = useState([]);
    const [grandtotal, setgrandtotal] = useState(0);
    const dispatch = useDispatch()
    const [orderData, setOrderData] = useState({});
    const [deleteData, setDeleteData] = useState({});
    const [payModal, setPayModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [checkoutModal, setCheckoutModal] = useState(false);
    useEffect(() =>{
        getAllCarts(dispatch).then(resp =>{
            setStoreCart(resp);
            var g=0;
            resp.forEach(item =>{
                    g+=(item.quantity*item.product.amount);
                });                
                setgrandtotal(g);
                paymentdetails.amount=g;
                paymentdetails.jambolo= 0.1 * g;
                paymentdetails.paystack=0.015 * g;
                paymentdetails.vat=0.075 * g;
                paymentdetails.total=paymentdetails.amount+paymentdetails.jambolo+paymentdetails.paystack+paymentdetails.vat;
                setOrderData(paymentdetails);
                setItemsCont(paymentdetails);
        })
    }, [dispatch]);
    const confirmPayment=()=>{
        setCheckoutModal(false);
        setPayModal(true);
    }
    const deleteCart=(prod)=>{
        setDeleteModal(true);
        setDeleteData(prod);
        //deleteCartFunc(prod?.id, dispatch)
    }
    const product = useSelector((state) => state?.cart?.cartsave);
    // const storeCarts = useSelector((state) => state?.cart?.allcarts);
    const total = useSelector((state) => state?.cart?.total);
    const {isFetching} = useSelector(state=>state?.cart);
    const handleQuantity12 = (type) => {
        if (type === "dec") {
            quantity > 1 && setQuantity(quantity - 1);
        } else {
            setQuantity(quantity + 1);
        }
    };
    const paymentdetails = {
        amount:0, qty:0, paystack:0, jambolo:0, vat:0, total:0, product_id:0};
// console.log("datatata", orderData)
    const handleAddToCart = (prod) => {
        const formData = new FormData();
        formData.append("product_id", prod?.id);
        formData.append("quantity", prod?.quantity);

        storeCart(dispatch, (formData))
    };
    const [quantity, setQuantity] = useState(1);
    const handleQuantity = (type, prod) => {
        const myNextList = [...storeCarts];
        if (type === "dec") {
            if(prod.quantity > 1)
            {
            
            const newstore= storeCarts.map(a=>{
                    if(a.product_id==prod.product_id){
                        let dt=a.quantity-1;                        
                        return {...a, quantity: dt}
                    }
                   
                    return a;
                });
                setStoreCart(newstore);
                calculateNewCart(newstore.filter(c=>c.product_id==prod.product_id)[0]);
                
            }
             
        } else {
            if(prod.quantity >= 1)
            {
            
            const newstore= storeCarts.map(a=>{
                    if(a.product_id==prod.product_id){
                        let dt=a.quantity+1;                        
                        return {...a, quantity: dt}
                    }
                    
                    return a;
                });
                setStoreCart(newstore);
                calculateNewCart(newstore.filter(c=>c.product_id==prod.product_id)[0]);
            }
        }
    };


    const calculateNewCart=(product)=>{        
        const formData = {
            product_id: product.product_id,
            quantity: product.quantity
          };

        updateCart(product.id, formData,dispatch);
        // paymentdetails.product_id=product.product_id;
        //     paymentdetails.amount=product.product.amount* product.quantity;
            
        //     paymentdetails.qty=product.quantity;
           paymentdetails.total=+paymentdetails.amount;
           
           // setOrderData(paymentdetails);
            //itemsCont.push(paymentdetails);
            //const data=itemsCont.filter(c=>c.product_id==product_id);
            var g= 0;
            storeCarts.forEach(item =>{
                if(product.product_id==item.product_id)
                {
                    g+=(product.quantity*item.product.amount);
                }
                else{
                    g+=(item.quantity*item.product.amount);
                }
                
            })
            //var g=grandtotal+ data[0].total;
            setgrandtotal(g);
            paymentdetails.amount=g;
                paymentdetails.jambolo= 0.1 * g;
                paymentdetails.paystack=0.015 * g;
                paymentdetails.vat=0.075 * g;
                paymentdetails.total=paymentdetails.amount+paymentdetails.jambolo+paymentdetails.paystack+paymentdetails.vat;
                setOrderData(paymentdetails);
                setItemsCont(paymentdetails);
            g=0;
            console.log(itemsCont);
    }
    
    
    return (
        <>
            <Navbar/>
            <section className="section page-section mtt-25rm">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                                {/*{ product?.map((prd, idx) =>*/}
                                {
                                    storeCarts.length > 0
                                    ?
                                    storeCarts?.map((prd, idx) =>
                                    // <div className="card list-item" style={{padding:"15px 20px 5px 20px"}} key={idx}>
                                    // <div className="row" style={{alignItems:"center"}}>
                                    //     <div className="col-md-2">
                                    //         <div className="list-image" style={{height:"auto"}}>
                                    //             <img src={Pic} alt="item image" />
                                    //             <div className="image-overlay"></div>
                                    //         </div>
                                    //     </div>
                                    //     <div className="col-md-4">
                                    //         <div className="content-wrap">
                                    //             <p className="list-title"><b>Product Name:</b> {prd?.name}</p>
                                    //             <p className="list-desc"><b>Description:</b>{prd?.desc}</p>
                                    //         </div>
                                    //     </div>
                                    //     <div className="col-md-4">
                                    //         <div className="content-wrap">
                                    //             <p className="list-desc"><b>Qty:</b> {prd?.quantity}</p>
                                    //             <p className="pricing"><b>Amount:</b>
                                    //                 <NumericFormat value= {(prd?.amount ? prd?.amount : 0)} displayType={'text'} prefix={'₦'} thousandSeparator={true} />
                                    //             </p>
                                    //         </div>
                                    //     </div>
                                    //     <div className="col-md-2">
                                    //         <div className="content-wrap">
                                    //             <button
                                    //                 onClick={() => handleAddToCart(prd)}
                                    //                     className="btn btn-primary btn-list">
                                    //                 Add To Cart
                                    //             </button>
                                    //         </div>
                                    //     </div>
                                    // </div>
                                    // </div>

                                        <div className="card list-item" style={{padding:"15px 20px 5px 20px"}} key={idx}>
                                            <div className="row" style={{alignItems:"center"}}>
                                                <div className="col-md-2">
                                                    <div className="list-image" style={{height:"auto"}}>
                                                        <img src={prd?.product.featured_images.length>0?prd?.product.featured_images[0].original_url: Pic} alt="item image" />
                                                        <div className="image-overlay"></div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="content-wrap">
                                                        <p className="list-title"><b>Product Name:</b> {prd?.product?.name}</p>
                                                        <p className="list-desc"><b>Description:</b>{prd?.product?.description}</p>
                                                    </div> 
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="content-wrap">
                                                    <FontAwesomeIcon
                                                        icon={faSubtract} size="1x"
                                                        onClick={() => handleQuantity("dec",prd)}
                                                        style={{
                                                        cursor: "pointer", position: "absolute", marginTop: "2px",
                                                        marginLeft: "35px", backgroundColor: "red",color: "white", width:"20", height:"20"
                                                        }}
                                                    />
                                                    <FontAwesomeIcon
                                                        icon={faAdd} size="1x"
                                                        onClick={() => handleQuantity("inc",prd)}
                                                        style={{
                                                        cursor: "pointer", position: "absolute", marginTop: "2px",
                                                        marginLeft: "100px", backgroundColor: "green",color: "white", width:"20", height:"20"
                                                        }}
                                                    />
                                                        <p className="list-desc"><b>Qty:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                                                        {/* <button
                                                            className="btn btn-danger btn-list"
                                                            style={{fontSize:"20px", color:"white", fontWeight:"bold", width:"50px"}}
                                                            onClick={() => handleQuantity("dec",prd)}
                                                        >
                                                            -
                                                        </button> */}
                                                         {prd?.quantity}
                                                         </p>
                                                        <p className="pricing"><b>Amount:</b>
                                                            <NumericFormat value= {(prd?.product?.amount ? prd?.product?.amount : 0)} displayType={'text'} prefix={'₦'} thousandSeparator={true} />
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="content-wrap">
                                                        <button
                                                            onClick={() =>deleteCart(prd)}
                                                            className="btn btn-danger btn-list" style={{color:"white", float:"right"}}>
                                                            remove
                                                        </button>
                                                        {/* <button
                                                            onClick={() => calculateCart(prd)}
                                                            className="btn btn-primary btn-list" style={{marginLeft:"5px"}}>
                                                            Order
                                                        </button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                )
                                :
                                        <div className="card list-item" style={{padding:"15px 20px 5px 20px"}}>
                                            <h3>No Available Records</h3>
                                        </div>
                                }
                        </div>
                        <div className="col-md-4">
                            <div className="card list-item" style={{padding:"20px"}}>
                                
                                <div className="content-wrap">
                                    <h4 className="list-title" style={{textAlign:"center"}}>Order Summary</h4>
                                    <div className="sm-wrap d-flex justify-content-between align-items-center mtt-2rm">
                                        <div>
                                            
                                            <p className="list-title"><b>Grand Total</b></p>
                                        </div>

                                        <div>                                            
                                            <p className="pricing">
                                                <b>
                                                    <NumericFormat value= {(grandtotal)} displayType={'text'} prefix={'₦'} thousandSeparator={true} />
                                                </b>
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="list-footer mtt-1rm">
                                {/*<button onClick={handleAddToCart} className="btn btn-primary btn-list">Add To Cart</button>*/}
                                <button
                                onClick={()=> setCheckoutModal(true)}
                                        className="btn btn-primary btn-list"
                                    style={{width:"100%"}}>
                                    Checkout Now
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <>
                    {payModal &&
                        <div className="modal-div overflow-auto">
                            <div className="modal-box-container">
                                <div className="col-6 col-md-6 table-popup">
                                        <div className="row mt-3">
                                            <span style={{fontSize:"20px", fontWeight:"bold"}} className="mb-5">Pay with:</span>
                                            <span className="mb-5">  
                                            <button
                                                onClick={()=>setPayModal(false)}
                                                className="btn btn-danger text-white fw-bold"
                                                style={{ float:"right", marginTop:"-38px" }}
                                            >
                                                    <span>x</span>
                                                </button></span>

                                            <div className="col-md-12 mtt-25rm">
                                                <Button
                                                    onClick={()=>walletCheckout(dispatch, {carts:itemsCont})}
                                                    loading={isFetching}
                                                    className="btn btn-danger text-white fw-bold"
                                                    style={{ width: "40%" }}
                                                >
                                                    Wallet
                                                </Button>

                                                <Button 
                                                    onClick={()=>paystackCheckout(dispatch, {carts:itemsCont,callbackUrl:
                                                        window.location.href})}
                                                    // loading={isFetching}
                                                    className="btn btn-danger text-white fw-bold"
                                                    style={{ width: "40%", float:"right" }}
                                                >
                                                    Paystack
                                                </Button>


                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    }

                {deleteModal &&
                        <div className="modal-div overflow-auto">
                            <div className="modal-box-container">
                                <div className="col-6 col-md-6 table-popup">
                                        <div className="row mt-3">
                                            <span style={{fontSize:"20px", fontWeight:"bold"}} className="mb-5"></span>
                                            <span className="mb-5">  
                                            <button
                                                onClick={()=>setDeleteModal(false)}
                                                className="btn btn-danger text-white fw-bold"
                                                style={{ float:"right", marginTop:"-38px" }}
                                            >
                                                    <span>x</span>
                                                </button></span>
Are you sure, you want to remove the item
                                            <div className="col-md-12 mtt-25rm">
                                                <Button
                                                    onClick={()=>deleteCartFunc(deleteData?.id, dispatch)}
                                                    loading={isFetching}
                                                    className="btn btn-danger text-white fw-bold"
                                                    style={{ width: "40%" }}
                                                >
                                                    Confirm
                                                </Button>

                                                

                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    }
                    {checkoutModal && 
                        <div className="modal-div overflow-auto">
                        <div className="modal-box-container">
                            <div className="col-6 col-md-6 table-popup">
                            <div className="row" style={{float: "right"}}>

                            <span className="mb-5">  
                                            <button
                                                onClick={()=>setCheckoutModal(false)}
                                                className="btn btn-danger text-white fw-bold"
                                            >
                                                    <span>x</span>
                                                </button></span>
                                        </div>
                                
                                <div className="content-wrap" style={{backgroundColor:"white"}}>
                                    <h4 className="list-title" style={{textAlign:"center"}}>Order Summary</h4>
                                    <div className="sm-wrap d-flex justify-content-between align-items-center mtt-2rm">
                                        <div>
                                            
                                            <p className="list-title">Amount</p>
                                            <p className="list-title">Paystack</p>
                                            <p className="list-title">Jambolo</p>
                                            <p className="list-title">VAT </p>
                                            <p className="list-title"><b>Total</b></p>
                                            
                                        </div>

                                        <div>
                                            
                                            <p className="pricing">
                                                <b>
                                                    <NumericFormat value= {(orderData.amount)} displayType={'text'} prefix={'₦'} thousandSeparator={true} />
                                                </b>
                                            </p>
                                            <p className="pricing">
                                                <b>
                                                    <NumericFormat value= {(orderData.paystack)} displayType={'text'} prefix={'₦'} thousandSeparator={true} />
                                                </b>
                                            </p>
                                            <p className="pricing">
                                                <b>
                                                    <NumericFormat value= {(orderData.jambolo)} displayType={'text'} prefix={'₦'} thousandSeparator={true} />
                                                </b>
                                            </p>
                                            <p className="pricing">
                                                <b>
                                                    <NumericFormat value= {(orderData.vat)} displayType={'text'} prefix={'₦'} thousandSeparator={true} />
                                                </b>
                                            </p>

                                            <p className="pricing">
                                                <b>
                                                    <NumericFormat value= {(orderData.total)} displayType={'text'} prefix={'₦'} thousandSeparator={true} />
                                                </b>
                                            </p>
                                            
                                        </div>
                                    </div>
                                </div>


                                <div className="list-footer mtt-1rm">                            
                                    <button
                                    onClick={()=>confirmPayment() 
                                    }
                                            className="btn btn-primary btn-list"
                                        style={{width:"100%"}}>
                                        Checkout Now
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </>
            </section>
            <Footer />
        </>
    );
};

export default Cart;
