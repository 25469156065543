import { createSlice } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
    name: "profile",
    initialState: {
        profile: null,
        isFetching: false,
        error: false,
    },
    reducers: {
        //GET Profile
        getProfileStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getProfileSuccess: (state, action) => {
            state.isFetching = false;
            state.profile = action.payload;
        },
        getProfileFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        updateProfileStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        updateProfileSuccess: (state, action) => {
            state.isFetching = false;
            state.profile=action.payload
        },
        updateProfileFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        updatePasswordStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        updatePasswordSuccess: (state, action) => {
            state.isFetching = false;
            // state.carts.push(action.payload);
        },
        updatePasswordFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

    },
});

export const {
    getProfileStart,
    getProfileSuccess,
    getProfileFailure,
    updateProfileStart,
    updateProfileSuccess,
    updateProfileFailure,
    updatePasswordStart,
    updatePasswordSuccess,
    updatePasswordFailure,
} = profileSlice.actions;

export default profileSlice.reducer;
