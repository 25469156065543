import React from "react";
import Story from "../assets/story.jpg";
import Mission from "../assets/mission.jpg";
import AboutBg from "../assets/about-us.jpg";
import Faces from "../assets/faces1.jpg";
import Faces2 from "../assets/faces2.jpg";
import Faces4 from "../assets/faces4.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faFacebook, faAmazon, faPaypal, faGooglePlay, faSlack, faCcMastercard, } from "@fortawesome/free-brands-svg-icons";
import Testimonial from "../organism/Testimonial";
import { Navbar } from "../organism/Navbar";
import Footer from "../organism/Footer";

const HowItWorks = () => {
  return (
    <>
      <Navbar />
      <section className="page-header with-bg" style={{ backgroundImage: `url(${AboutBg})` }}>
        <div className="row page-header-row justify-content-center">
          <div class="col-md-8 title-wrap text-center">
            <h2 className="page-title text-white">How It Works</h2>
            <p className="text-white">Discover some of the most popular listing in your area based on reviews and ratings.</p>
          </div>
        </div>
      </section>
       <section class="section bg-light" id="our mission">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={Mission} alt="story" className="img-fluid" style={{ height: 400, width: "100%" }} />
            </div>
            <div className="col-md-6 text-col-2">
              <div>
                <h4 className="fw-bold col-title">Our Mission</h4>
                <p className="lh-lg" >
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Similique distinctio nobis debitis recusandae sapiente necessitatibus voluptatibus in explicabo tempore veritatis, voluptatem aliquam voluptas maxime accusamus ex quod aperiam laborum alias!
                </p>
              </div>
              <div>
                <h4 className="fw-bold col-title">Our Vision</h4>
                <p className="lh-lg">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus saepe iste at optio cum repellat, accusantium quas magni, asperiores dolorum eaque, inventore id perferendis earum omnis. Sunt modi odio officia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title text-center">
                <h4 className="fw-bold text-light">Our Team</h4>
                <p className="subtitle">Meet the People behind Jambolo</p>
              </div>
            </div>
            <div class="row team-wrap">
              <div className="col-md-4">
                <div className="team-member">
                  <div className="team-image">
                    <img src={Faces} alt="ceo" />
                  </div>
                  <div className="team-details text-center text-light">
                    <p className="team-title">John Doe</p>
                    <p className="m-0">Chief Executive Officer</p>
                    <div className="sm-wrap d-flex justify-content-center align-items-center">
                      <FontAwesomeIcon icon={faFacebook} className="mx-2" />
                      <FontAwesomeIcon icon={faLinkedin} className="mx-2" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="team-member">
                  <div className="team-image">
                    <img src={Faces2} alt="ceo" />
                  </div>
                  <div className="team-details text-center text-light">
                    <p className="team-title">Jane Dean</p>
                    <p className="m-0">Project Manager</p>
                    <div className="sm-wrap  d-flex justify-content-center align-items-center">
                      <FontAwesomeIcon icon={faFacebook} className="mx-2" />
                      <FontAwesomeIcon icon={faLinkedin} className="mx-2" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="team-member">
                  <div className="team-image">
                    <img src={Faces4} alt="ceo" />
                  </div>
                  <div className="team-details text-center text-light">
                    <p className="team-title">Peter Coleman</p>
                    <p className="m-0">Human Resource Mgr</p>
                    <div className="sm-wrap d-flex justify-content-center align-items-center">
                      <FontAwesomeIcon icon={faFacebook} className="mx-2" />
                      <FontAwesomeIcon icon={faLinkedin} className="mx-2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="section bg-white footer-icons">
        <div className="container">
          <div className="row">
            <div className="col-md-12 as-seen">
              <div className="d-flex justify-content-evenly align-items-center">
                <FontAwesomeIcon icon={faAmazon} size="5x" />
                <FontAwesomeIcon icon={faPaypal} size="5x" />
                <FontAwesomeIcon icon={faGooglePlay} size="5x" />
                <FontAwesomeIcon icon={faSlack} size="5x" />
                <FontAwesomeIcon icon={faCcMastercard} size="5x" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default HowItWorks;
