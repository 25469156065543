import React from "react";
import SignUp from "../molecules/SignUp";
import logo from "../assets/jambolologo.png";

const CreateAccount = () => {
  return (
    <>
      <div
        className="modal fade"
        id="signupModal"
        tabIndex="-1"
        aria-labelledby="signupModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content p-0">
            <div className="modal-body p-0" style={{ height: "auto" }}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 bg-dark">
                    <div
                      className="d-flex text-center"
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "100px 0px",
                      }}
                    >
                      <img src={logo} alt="logo" />
                      <p className="text-light">
                        Connecting you to artisans close by...
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    style={{
                      // padding: "100px 20px",
                      backgroundColor: "#dcdcdc",
                    }}
                  >
                    <div className="text-end p-2">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div
                      style={{
                        padding: "60px 20px",
                      }}
                    >
                      <h4 className="text-center fw-bolder">
                        Create an account
                      </h4>
                      <p className="text-center" style={{ fontSize: 16 }}>
                        Fill in all blank spaces with the correct credentials
                      </p>
                      <div>
                        <SignUp />
                      </div>
                      <p className="pt-2 text-center" style={{ fontSize: 14 }}>
                        Already have an account?{" "}
                        <span className="text-danger" style={{cursor:"pointer"}}
                              data-bs-toggle="modal" data-bs-target="#exampleModal"   data-bs-dismiss="modal"
                              aria-label="Close">
                          Login
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAccount;
