import { createSlice } from "@reduxjs/toolkit";

export const productSlice = createSlice({
    name: "product",
    initialState: {
        product: null,
        products: [],
        isFetching: false,
        error: false,
    },
    reducers: {
        //GET ALL
        getProductStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getProductSuccess: (state, action) => {
            state.isFetching = false;
            state.products = action.payload;
        },
        getProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //GET Single Product
        getSingleProductStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getSingleProductSuccess: (state, action) => {
            state.isFetching = false;
            state.product = action.payload;
        },
        getSingleProductFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const {
    getProductStart,
    getProductSuccess,
    getProductFailure,
    getSingleProductStart,
    getSingleProductSuccess,
    getSingleProductFailure,
} = productSlice.actions;

export default productSlice.reducer;
