import {publicRequest, userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getTransactionFailure,
    getTransactionStart,
    getTransactionSuccess,
} from "../transactionRedux";

export const getArtisan = async () => {
    try {
        const res = await userRequest.get(`/user/transactions/artisan`);
        return res.data.data.artisan;
    } catch (err) {
        Notification.error(err.response.data.message);
    }
};

export const getTransaction = async (dispatch, type, pageNum) => {
    // alert(5)
    dispatch(getTransactionStart());
    try {
        const res = await userRequest.get(type === "wallet"?
            `/user/transactions?page=${pageNum}&limit=15&filter[payment_purpose]=wallet-topup`
            :type === "order"
                ?`/user/transactions?page=${pageNum}&limit=15&filter[payment_purpose]=callout-charge`
            :`/user/transactions?page=${pageNum}&limit=15`);
        //dispatch(getTransactionSuccess(res.data.data.transactions));
        // Notification.success(res.data.message);
        return res.data.data.transactions;
    } catch (err) {
        dispatch(getTransactionFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePageChange = async (pageNum, perPage, dispatch) => {
    // alert(5)
    dispatch(getTransactionStart());
    try {
        const res = await userRequest.get(`/user/transactions?page=${pageNum}&limit=${perPage}`);
        dispatch(getTransactionSuccess(res.data.data.transactions));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getTransactionFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePerRowsChange = async (page, perPage, dispatch) => {
    // alert(5)
    dispatch(getTransactionStart());
    try {
        const res = await userRequest.get(`/user/transactions?page=${page}&limit=${perPage}`);
        dispatch(getTransactionSuccess(res.data.data.transactions));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getTransactionFailure());
        Notification.error(err.response.data.message);
    }
};
