import React, {useEffect, useState} from "react";
import Moment from "react-moment";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyBills, faMoneyBillTransfer, faUsersLine, faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import {getTransaction} from "../../redux/ApiCalls/transactionCalls";
import {useNavigate } from 'react-router';


const Userdashboard = () => {
  const dispatch = useDispatch()
  const location = useLocation();
  const [transactions,setTransaction] = useState({});
  
  useEffect( () => {
    
    getTransaction(dispatch, "").then(trans=>{
      setTransaction(trans)
    })
  }, [dispatch])
   

  return (
    <>
      {/* <Sidebar /> */}
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-3">
          <div className="card  mt-5 p-3 card-height">
            <div className="d-flex justify-content-between lh-lg pd-10">
              <div
                className="border me-3 p-2"
                style={{borderRadius: '50%', width: 50, height: 50, textAlign: 'center', background:"#dc3545"}}
              >
                <FontAwesomeIcon icon={faMoneyBills}  className='mx-2 text-white'/>
              </div>

              <div>
                <p className="mb-5">Total Revenue</p>
                <h5 className="text-start fw-medium">&#8358; 4500</h5>
              </div>
            </div>
            {/*<h3 className="text-start fw-medium  mt-3 ml-20">&#8358; 4500</h3>*/}
          </div>
        </div>
        <div className="col-md-3">
          <div className="card  mt-5 p-3 card-height">
            <div className="d-flex justify-content-between lh-lg pd-10">
              <div
                className="border bg-success me-3 p-2"
                style={{borderRadius: '50%', width: 50, height: 50, textAlign: 'center'}}
              >
                <FontAwesomeIcon icon={faMoneyBillTransfer}  className='mx-2 text-white'/>
              </div>
              <div>
                <p className="mb-5">Transactions</p>
                <h5 className="text-start fw-medium">&#8358; 2000</h5>
              </div>
            </div>
            {/*<h3 className="text-start fw-medium  mt-3 ml-20">&#8358; 250</h3>*/}
          </div>
        </div>
        <div className="col-md-3">
          <div className="card  mt-5 p-3 card-height">
            <div className="d-flex justify-content-between lh-lg pd-10">
              <div
                className="border bg-dark me-3 p-2"
                style={{borderRadius: '50%', width: 50, height: 50, textAlign: 'center'}}
              >
                <FontAwesomeIcon icon={faUsersLine}  className='mx-2 text-white'/>
              </div>
              <div>
                <p className="mb-5">Customers</p>
                <h5 className="text-start fw-medium">&#8358; 3500</h5>
              </div>
            </div>
            {/*<h3 className="text-start fw-medium  mt-3 ml-20">&#8358; 3500</h3>*/}
          </div>
        </div>
        <div className="col-md-3">
          <div className="card  mt-5 p-3 card-height">
            <div className="d-flex justify-content-between lh-lg pd-10">
              <div
               className="border bg-primary me-3 p-2"
               style={{borderRadius: '50%', width: 50, height: 50, textAlign: 'center'}}
              >
                <FontAwesomeIcon icon={faClipboardCheck}  className='mx-2 text-white'/>
              </div>
              <div>
                <p className="mb-5">Products</p>
                <h5 className="text-start fw-medium">&#8358; 2000</h5>
              </div>
            </div>
            {/*<h3 className="text-start fw-medium  mt-3 ml-20">&#8358; 2100</h3>*/}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card mt-3 p-3 lh-lg">
            <h4 className="card-title mt-3 fw-bold" style={{fontSize: 18}}>Recent Transactions</h4>
            <div className="card-body">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Date created</th>
                    <th scope="col">Payment Type</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Purpose</th>
                    <th scope="col">Status</th>
                    {/*<th scope="col">Action</th>*/}
                  </tr>
                </thead>
                <tbody>
                {
                  transactions?.data?.slice(0, 10).map((data, i) =>
                      <tr className="tr pointer cursor-pointer" key={i}>
                        <td className="td">
                          <Moment format="D MMM YYYY">{data?.created_at}</Moment>
                        </td>
                        <td className="td">{data?.payment_method}</td>
                        <td className="td">₦{data?.amount}</td>
                        <td className="td">{data?.payment_purpose}</td>
                        <td className="td">{data?.payment_status ?
                            <span className="btn btn-sm btn-info text-white">Success</span>
                            :
                            <span className="btn btn-sm btn-danger text-white">Failed</span>}
                        </td>
                      </tr>
                  )
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Userdashboard;
