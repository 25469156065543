import React from "react";
import Tcard from '../molecules/Tcard'

const Testimonial = () => {
  return (
    <>
	<section className="section testimonials">
		<div className="container">
			<div className="row">
				<div className="col-md-12">
					<div className="section-title text-center">
						<h4 className="fw-bold">Testimonials</h4>
						<p className="subtitle">What our clients say about us</p>
					</div>
				</div>
			</div>
			<div class="testimonial-wrap">
				<div class="row">
					<Tcard />
				</div>				
			</div>       
        </div>
    </section>
    </>
  );
};

export default Testimonial;
