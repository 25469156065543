import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
const SearchButton = ({ searchFunc, handleScrollSwap }) => {
  return (
    <>
      <button onClick={searchFunc} className="btn btn-danger d-flex search-btn">
        <FontAwesomeIcon icon={faMagnifyingGlass} color="#fff" className='p-2' />
      </button>
    </>
  );
};

export default SearchButton;
