import React, { createContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import LoginModal from "../organism/LoginModal";
import CreateAccount from "../organism/CreateAccount";
import ForgotModal from "../organism/ForgotModal";
import logo from "../assets/jambolologo.png";
import Logo from "../assets/brand/jlogom.png";
import LogoWhite from "../assets/brand/jlogow.png";
import { useSelector, useDispatch } from "react-redux";
import { logoutFunc } from "../../redux/ApiCalls/authCalls";
import {getAllCarts} from "../../redux/ApiCalls/cartCalls";
import Modal from "react-modal";

export const useScrollHandler = () => {
  const [scroll, setScroll] = useState(1);

  useEffect(() => {
    const onScroll = () => {
      const scrollCheck = window.scrollY > 200;
      setScroll(scrollCheck);
    };
    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [scroll, setScroll]);

  return scroll;
};

export const ModalContext = createContext();

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal]= useState(false);
  const scroll = useScrollHandler();
  const location = useLocation();
  const page = location.pathname.split('/')[1];
  const dispatch = useDispatch()

  const storeCarts = useSelector((state) => state?.cart?.allcarts);
  const { isFetching, error, currentUser, isSuccess } = useSelector(state => state.user)
  const quantity = useSelector(state=>state.cart.quantity)
  const carts = useSelector(state=>state.cart.carts)

  const total = useSelector(state=>state.cart.total)

  useEffect(() =>{
    currentUser && getAllCarts(dispatch)
  }, [dispatch])


  return (
    <>
      <nav className={scroll ? "navbar fixed-top navbar-expand-lg sticky" : "navbar fixed-top navbar-expand-lg"}>
        <div className="container-fluid">
          <a className="navbar-brand er mx-3" href="/" style={{ fontSize: 30 }}>
            <img src={Logo} alt="Jambolo" className="dark-logo" />
            <img src={LogoWhite} alt="Jambolo" className="main-logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
            aria-label="Toggle navigation"><span className="navbar-toggler-icon" style={{color: "white", fontSize: 'xx-large'}}>V</span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/" className={"nav-link" + (page === "" ? " active" : "")} aria-current="page">Home</Link>
              </li>

              <li className="nav-item">
                <Link to="/marketplace" className={"nav-link mx-2" + (page === "marketplace" ? " active" : "")}>Marketplace</Link>
              </li> 

              {/* <li className="nav-item">
                <Link to="/posts" className={"nav-link mx-2" + (page === "posts" ? " active" : "")}>Posts</Link>
              </li> */}

              <li className="nav-item">
                <Link to="/aboutus" className={"nav-link mx-2" + (page === "aboutus" ? " active" : "")}>About us</Link>
              </li>

              {
                currentUser?.data?.user
                  ?
                  <>
                    <li className="nav-item">
                      <Link to="/dashboard/index" className="nav-link mx-2">Dashboard</Link>
                    </li>
                    <li className="nav-item cursor-pointer pointer"
                      onClick={() => logoutFunc(dispatch)} style={{ cursor: "pointer" }}>
                      <a className="nav-link mx-2 cursor-pointer pointer" data-bs-toggle="modal">Logout</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="/cart" style={{ display: "flex" }}>

                      <button type="button" className="btn  position-relative">
                      <FontAwesomeIcon icon={faShoppingCart} />
                      <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                        {storeCarts?.length}
                        {/*{quantity}*/}
                      </span>
                    </button>
                      </a>
                    </li>
                  </>
                  :
                  <>
                    <li className="nav-item">
                      <a className="nav-link mx-2" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" >Login</a>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <a className="nav-link mx-2" href="0#" data-bs-toggle="modal" data-bs-target="#forgotPasswordModal">Forgot</a> onClick={()=>{setOpenModal(true)}}*/}
                    {/*</li>*/}
                    <li className="nav-item">
                      <a className="nav-link mx-2" href="0#" data-bs-toggle="modal" data-bs-target="#signupModal">Sign Up</a>
                    </li>
                  </>

              }
            </ul>
          </div>
        </div>
      </nav>
      <Modal isOpen={isOpen}>
        
        <br />
        <button onClick={() => setIsOpen(false)}>close</button>
      </Modal>
      <ModalContext.Provider value={{ setIsOpen }}>
      <LoginModal />
        
      </ModalContext.Provider>
      {/* {openModal && <LoginModal closeModal={ setOpenModal}/>} */}
      {/* <LoginModal /> */}
      <CreateAccount />
      <ForgotModal />
    </>
  );
};
