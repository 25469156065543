import React, {useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import Pic from '../assets/wood.jpg'
import HeaderBg from '../assets/market-place.jpg'
import {Navbar} from '../organism/Navbar'
import Footer from '../organism/Footer'
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getAllProducts, getSingleProduct} from "../../redux/ApiCalls/productCalls";
import {storeCart} from "../../redux/ApiCalls/cartCalls";
import { addCartProduct } from "../../redux/cartRedux";
import ImageGallery from 'react-image-gallery';

const MarketCart = () => {
    const {id} = useParams();
    //const [prod, setProduct] = useState({});    
    const [quantity, setQuantity] = useState(1);

    const handleQuantity = (type) => {
        if (type === "dec") {
            quantity > 1 && setQuantity(quantity - 1);
        } else {
            setQuantity(quantity + 1);
        }
    };

   
    
      const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
      }
      const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
      }

    const dispatch = useDispatch()
    const [product,setProduct] = useState({});
    let data=[];
    useEffect( () => {
        getSingleProduct(dispatch, id).then(res =>{
            setProduct(res);
           
        })
    }, [dispatch])
    const {isFetching} = useSelector(state=>state?.product);
   // 
    
   product?.featured_image?.data.forEach(function (item, index) {
        
    var m={'original':item.url,'thumbnail':item.url};
   data.push(m);
}); 
      
      if(data.length==0){
        // window.NavigationPreloadManager
      data.push({'original':Pic, 'thumbnail':Pic});
      }
// console.log("QUNTNTNTNT", quantity)
// console.log("PRODUCT IIIIDDD", product?.id)

    const handleClick = () => {
        const formData = new FormData();
        formData.append("product_id", product?.id);
        formData.append("quantity", quantity);

        storeCart(dispatch, (formData))
    };

    // const handleClick = () => {
    //     dispatch(
    //         addCartProduct({ ...product, quantity })
    //     );
    // };
    return (
        <>
            <Navbar />
            <section className="section page-section mtt-25rm">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            
                        <ImageGallery
                        originalHeight ="100px"
                        items={data}
                        showBullets={true}
                        />
                                
                                {/* <Carousel
                                    data={data}
                                    time={2000}
                                    width="850px"
                                    height="500px"
                                    captionStyle={captionStyle}
                                    radius="10px"
                                    slideNumber={true}
                                    slideNumberStyle={slideNumberStyle}
                                    captionPosition="bottom"
                                    automatic={true}
                                    dots={true}
                                    pauseIconColor="black"
                                    pauseIconSize="40px"
                                    slideBackgroundColor="white"
                                    slideImageFit="cover"
                                    thumbnails={true}
                                    thumbnailWidth="100px"
                                    style={{
                                    textAlign: "center",
                                    maxWidth: "850px",
                                    maxHeight: "500px",
                                    margin: "40px auto",
                                    }}
                                /> */}
                                    <div className="image-overlay"></div>
                                    <span className="list-sector">{product?.name}</span>
                               
                            
                        </div>
                        <div className="col-md-4">
                            <div className="list-content">
                                <div className="content-wrap">
                                    <p className="list-title">{product?.name}</p>
                                    <p className="pricing"><span>&#8358;</span> {product?.amount}</p>
                                    <p className="list-desc">{product?.description}</p>
                                    <div className='list-meta'>
                                        <div className="meta-item">
                                            <span><FontAwesomeIcon icon={faLocationDot} /></span>
                                            <span className="mx-2 my-0">{product?.artisan?.address}</span>
                                        </div>
                                        <div className="meta-item">
                                            <span><FontAwesomeIcon icon={faPhone} /></span>
                                            <span className="mx-2">+{product?.artisan?.phone.slice(0,6)} xxx xxxx</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="list-footer mtt-1rm">
                                <button
                                    className="btn btn-danger btn-list"
                                    style={{fontSize:"20px", color:"white", fontWeight:"bold", width:"50px"}}
                                    onClick={() => handleQuantity("dec")}
                                >
                                    -
                                </button>
                                <input
                                    style={{width:"40px", height:"35px", fontWeight:"bold",  textAlign:"center"}}
                                    value={quantity}
                                    disabled
                                />
                                <button
                                    className="btn btn-primary btn-list"
                                    style={{fontSize:"20px", width:"50px"}}
                                    onClick={() => handleQuantity("inc")}
                                >
                                    +
                                </button>
                            </div>
                            <div className="list-footer mtt-1rm">
                                {/*<button onClick={handleAddToCart} className="btn btn-primary btn-list">Add To Cart</button>*/}
                                <button onClick={handleClick} className="btn btn-primary btn-list">Add To Cart</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default MarketCart;
