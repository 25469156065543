import React, { useState, useEffect } from "react";
import {verifyEmail} from "../../redux/ApiCalls/authCalls";
import {useDispatch, useSelector} from "react-redux";
import { Navbar } from "../organism/Navbar";
import Footer from "../organism/Footer";
import Button from "../Buttons";

const VerifyEmail = () => {
	const [email, setEmail] = useState("");

	const dispatch = useDispatch()
const expires = "jdfdjkdjkdfjkdf";
const hash = "jdfdjkdjkdfjkdf";
const user_id = "1";
const signature = "jdfdjkdjkdfjkdf";
	useEffect(() => {
		verifyEmail(dispatch, expires, hash, user_id, signature );
	}, []);


	const {isFetching, error, currentUser, isSuccess} = useSelector(state=>state.user)

	return (
    <>
      <Navbar />

	 <section class="section about-us bg-white mtt-25rm">
		<div className="container">
			<div className="row">
				<div className="col-md-4 text-col">
				</div>
				<div className="col-md-4 text-col">
					<>
						<form>
							<div className="row">
								<div className="col-md-12">
									<label htmlFor="email">Verification Link</label>
									<input
										className="form-control"
										type="text"
										placeholder="verifcation link here"
										style={{ width: "100%" }}
										onChange={({ target: { value } }) => setEmail(value)}
									/>
								</div>
							</div>
							<div className="row mt-3">
								<div className="col-md-12">
									<Button
										loading={isFetching}
										className="btn btn-danger text-white fw-bold"
										style={{ width: "100%" }}
									>
										 Verify
									</Button>
								</div>
							</div>
						</form>
					</>
				</div>
				<div className="col-md-4 text-col">
				</div>
			</div>
		</div>
	 </section>


    <Footer />
    </>
  );
};

export default VerifyEmail;
