import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerFunc } from "../../redux/ApiCalls/authCalls";
import Button from "../Buttons";
import { useLocation } from "react-router";

const SignUp = () => {
  const [first_name, setFirstname] = useState("");
  const [last_name, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setConfirmpassword] = useState("");
  const location = useLocation()
  const [route, setRoute] = useState({ //--> It can be replaced with useRef or localStorage
    to: location.pathname,
    from: location.pathname //--> previous pathname
  });
  const dispatch = useDispatch()

  const onsubmit = (e) => { 
    e.preventDefault()
    registerFunc(dispatch, { first_name, last_name, email, password, password_confirmation }).then(resp=>{
      
      if(resp=="User registration was successful!!!"){
        // if(route.from!="/")
        // {
          // window.location.href=route.from;
          const body=document.body;
          body.className='';
          body.style='';	
          const element = document.getElementById('signupModal');			
          element.className="modal fade";
          document.body.style.overflow = 'scroll';
        // }
        // else{        
        // window.location.href = "/dashboard/index"
        // }
      }
    })
  }

  const { isFetching } = useSelector(state => state.user)

  return (
    <>
      <form onSubmit={onsubmit}>
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="first_name">First Name</label>
            <input
              className="form-control"
              type="text"
              name="first_name"
              placeholder="Enter first name"
              onChange={(e) => setFirstname(e.target.value)}
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="last_name">Last Name</label>
            <input
              className="form-control"
              type="text"
              placeholder="enter last name"
              onChange={(e) => setLastname(e.target.value)}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label htmlFor="email">Email</label>
            <input
              className="form-control"
              type="email"
              placeholder="enter email"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label htmlFor="password">Password</label>
            <input
              className="form-control"
              type="password"
              name="password"
              placeholder="enter password"
              onChange={(e) => setPassword(e.target.value)}
              style={{ width: "100%", fontSize: "25px" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <label htmlFor="confirm_password">Confirm password</label>
            <input
              className="form-control"
              type="password"
              name="password_confirmation"
              placeholder="enter confirm password"
              onChange={(e) => setConfirmpassword(e.target.value)}
              style={{ width: "100%" }}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <Button
                loading={isFetching}
                className="btn btn-danger text-white fw-bold"
                style={{ width: "100%" }}
            >
              Sign Up
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default SignUp;