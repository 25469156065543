import React, { useState } from "react";
import {loginFunc} from "../../redux/ApiCalls/authCalls";
import {useDispatch, useSelector} from "react-redux";
import Button from "../Buttons";


const ForgotForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch()

  const Submit = (e) => {
    e.preventDefault();
    // loginFunc(dispatch,{email, password})
  };
  const {isFetching} = useSelector(state=>state.user)

  return (
    <>
      <form onSubmit={Submit}>
        <div className="row">
          <div className="col-md-12">
            <label htmlFor="email">Email</label>
            <input
              className="form-control"
              type="text"
              placeholder="Email Address"
              style={{ width: "100%" }}
              onChange={({ target: { value } }) => setEmail(value)}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <Button
                loading={isFetching}
                className="btn btn-danger text-white fw-bold"
                style={{ width: "100%" }}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ForgotForm;
