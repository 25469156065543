import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
 faQuoteLeft
} from "@fortawesome/free-solid-svg-icons";
const Tcard = () => {
  const list = [
    {
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.",
      name: "John Doe",
       role: 'Plumber'
    },
    {
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...",
      name: "Jane Leone",
      role: 'Hair Stylist'
    },
    {
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris...",
        name: 'Mohammed S.',
      role: "Auto Mechanic"
    },
  ];
  return (
    <>
       {list.map((d) => (
          <div className="col-md-4">
			<div className="testimonial-item text-center">
				<div className='icon text-center'>
					<FontAwesomeIcon icon={faQuoteLeft} size='3x'/>
				</div>
				<p className='lh-lg testi'>{d.text}</p>
				<h6 className='text-center fw-bold name'>{d.name}</h6>
				<p className='text-center role'>{d.role}</p>
            </div>
          </div>
        ))}      
    </>
  );
};

export default Tcard;
