import { useRef, useEffect, useState } from "react";
import "../../index.css";
import logo from "../assets/jambolologo.png";
import MapModal from "../organism/MapModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faDeleteLeft } from "@fortawesome/free-solid-svg-icons";


const AutoComplete = (props) => {
 const autoCompleteRef = useRef();
 const [isValid, setIsValid] = useState(true);
 const inputRef = useRef();
 var fade="hidden";
 const options = {
    componentRestrictions: { country: "ng" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["geocode"]
   };
 useEffect(() => {
  autoCompleteRef.current = new window.google.maps.places.Autocomplete(
   inputRef.current,
   options
  );
  
  autoCompleteRef.current.addListener("place_changed", async function () {
   const place = await autoCompleteRef.current.getPlace();
    props.onChange(place);
    // let cus_location = {
    //   lat: place.geometry.location.lat(),
    //   long: place.geometry.location.lng()
    // }
   console.log({ place });
  });  
  
 }, []);
 const EnableMap =()=>{
    if(isValid)  setIsValid(false);
    else  setIsValid(true);
 }
 const clearLocation=()=>{
  inputRef.current.value="";
 }
 
 const previewMap= ()=>{
  
 }
 return (
  <div style={{width:"56%", background: "white"}}>
   {/* <label>Enter Address :</label> */}
   
   <FontAwesomeIcon
   icon={faCircleXmark}
     size="2x"
    onClick={() => clearLocation()}
    style={{
      cursor: "pointer", position: "absolute", marginTop: "15px",
      marginLeft: "23%", fontSize: '20px'
    }}
  />
   <input className="form-control mx-2 artisan_category" onFocus={EnableMap} ref={inputRef}/>
   <div id="geo-dropdown">
    <div className="geo-option" style={!isValid ? {} : { display: 'none' }} data-bs-toggle="modal" data-bs-target="#mapModal" data-bs-dismiss="modal">
        <img src={logo} alt="icon-map" style={{width: `50px`}}/>
        <span className="pls">
            Choose on map 
        </span>
          </div>
    </div>
    <MapModal />
  </div>
 );
};
export default AutoComplete;