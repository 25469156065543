import React from "react";

const RequestForm = () => {
  return (
    <>
      <form>
        <div className="row">
          <div className="col-md-12">
            <label htmlFor="profession">Profession</label>
            <input
              type="text"
              placeholder="enter profession"
              className="form-control"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <label htmlFor="duration">Duration</label>
            <input
              type="text"
              placeholder="enter duration"
              className="form-control"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <label htmlFor="price">Price</label>
            <input
              type="number"
              placeholder="enter price"
              className="form-control"
            />
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <button className='btn btn-danger' style={{width: '100%'}}>Make a request</button>
          </div>
        </div>
      </form>
    </>
  );
};

export default RequestForm;
