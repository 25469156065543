import React from "react";
import Home from "./components/pages/Home";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Aboutus from "./components/pages/Aboutus";
import Faq from "./components/pages/Faq";
import HowItWorks from "./components/pages/HowItWorks";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import Scope from "./components/pages/Scope";
import TermsConditions from "./components/pages/TermsConditions";
import Dashboard from "./components/pages/Dashboard";
import Index from "./components/dashboard-pages/Index";
import Profile from "./components/dashboard-pages/Profile";
import OurTeam from "./components/pages/OurTeam";
import Roadmap from "./components/pages/Roadmap";
import MakeaRequest from "./components/dashboard-pages/MakeaRequest";
import Requests from './components/dashboard-pages/Requests';
import Market from './components/dashboard-pages/MarketOrder';
import Wallet from './components/dashboard-pages/wallet';
// import singleWallet from './components/dashboard-pages/singleWallet';
import MarketplaceBefore from './components/pages/Marketplace';
import Cart from './components/molecules/Cart';
import MarketCart from './components/molecules/MarketCart';
import Category from './components/pages/Category'
import VerifyEmail from './components/pages/verifyEmail'
import Post from './components/pages/Posts';
import Transaction from "./components/molecules/Transactions";


const Entry = () => {
  const user = useSelector(state => state.user.currentUser)
  return (
    <>
      {/* content routing is done here */}
      <Routes>
        <Route index element={<Home />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/ourteam" element={<OurTeam />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/howitworks" element={<HowItWorks />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/scope" element={<Scope />} />
        <Route path="/termscondtion" element={<TermsConditions />} />

        <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to={'/'} />} >
          <Route index element={<Index />} />
          <Route path="index" element={<Index />} />
          <Route path="profile" element={user ? <Profile /> : <Navigate to={'/'} />} />
          <Route path="make-a-request" element={user ? <MakeaRequest /> : <Navigate to={'/'} />} />
          <Route path="requests" element={user ? <Requests /> : <Navigate to={'/'} />} />
          <Route path="market" element={user ? <Market /> : <Navigate to={'/'} />} />
          <Route path="wallet" element={user ? <Wallet /> : <Navigate to={'/'} />} />
          <Route path="transactions" element={user ? <Transaction /> : <Navigate to={'/'} />} />
          {/*<Route path="wallet/:id" element={user ? <singleWallet /> : <Navigate to={'/'} />} />*/}
        </Route>
        <Route path="/marketplace" element={<MarketplaceBefore />} />
        {/* <Route path="/marketplace" element={user ? <MarketplaceBefore /> : <Navigate to={'/'} />} /> */}
        <Route path="/marketcart/:id" element={user ? <MarketCart /> : <Navigate to={'/'} />} />
        <Route path="/cart" element={user ? <Cart /> : <Navigate to={'/'} />} />
        <Route path="/categories" element={<Category />} />
        <Route path="/verify" element={<VerifyEmail />} />
        {/* <Route path="/posts" element={<Post />} /> */}
      </Routes>
    </>
  );
};

export default Entry;
