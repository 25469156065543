import React from "react";
import LoginForm from "../molecules/LoginForm";
import logo from "../assets/jambolologo.png";
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import { useRef, useEffect, useState } from "react";

let markerArray = [];

const MapModal = () => {
  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const [state, setState] = useState({
      currentLocation: { lat: 0, lng: 0 },
      markers: [],
      bounds: null
    });

    navigator?.geolocation.getCurrentPosition(
            ({ coords: { latitude: lat, longitude: lng } }) => {
              const pos = { lat, lng };              
              setState({ currentLocation: pos });
            }
          );
    // navigator.geolocation.getCurrentPosition(function(position) {
    //   const pos = { lat, lng };
    //     setState({ currentLocation: pos });
    //   console.log("Latitude is :", position.coords.latitude);
    //   console.log("Longitude is :", position.coords.longitude);
    // });
  //  state = {
  //   currentLocation: { lat: 0, lng: 0 },
  //   markers: [],
  //   bounds: null
  // };
  const google = window.google;
  
 
 const onMapLoad = map => {
    navigator?.geolocation.getCurrentPosition(
      ({ coords: { latitude: lat, longitude: lng } }) => {
        const pos = { lat, lng };
        setState({ currentLocation: pos });
      }
    );
    google.maps.event.addListener(map, "bounds_changed", () => {
      // console.log(map.getBounds());
      
      setState({ bounds: map.getBounds() });
    });
  };
    var searchBox=[];
  const onSBLoad = ref => {
     searchBox = ref;
  };

 const onPlacesChanged = () => {
    markerArray = [];
    let results = searchBox.getPlaces();
    for (let i = 0; i < results.length; i++) {
      let place = results[i].geometry.location;
      markerArray.push(place);
    }
    setState({ markers: markerArray });
    console.log(markerArray);
  };




  const containerStyle = {
    width: '100%',
    height: '400px'
  };
  
  const center = {
    lat: 6.5896448,
    lng: 3.3456128
  };
  return (
    <>
      <div
        className="modal fade"
        id="mapModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content p-0">
            <div className="modal-body p-0" style={{ height: "auto" }}>
              <div className="container-fluid">
                <div className="row">
                  
                  <div
                    className="col-md-12"
                    style={{
                      // padding: "100px 20px",
                      backgroundColor: "#dcdcdc",
                    }}
                  >
                    <div className="text-end p-2">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    
                    <div id="searchbox" style={{zIndex: 1, position: `absolute`, margin: `10px 250px`}}>
          <StandaloneSearchBox
            onLoad={onSBLoad}
            onPlacesChanged={onPlacesChanged}
            bounds={state.bounds}
          >
            <input
              type="text"
              placeholder="search your location"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px"
              }}
            />
          </StandaloneSearchBox>
        </div>
                      <GoogleMap
                        
                        mapContainerStyle={containerStyle}
                        // center={center}
                        center={state.currentLocation}
                        onLoad={onMapLoad}
                        
                        zoom={10}
                      >
                         {/* {state.markers.map((mark, index) => (
                          <Marker key={index} position={mark} />
                        ))} */}
                        { /* Child components, such as markers, info windows, etc. */ }
                        <></>
                      </GoogleMap>
      
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MapModal;
