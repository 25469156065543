import { createSlice } from "@reduxjs/toolkit";

export const transactionSlice = createSlice({
    name: "transactions",
    initialState: {
        transactions: [],
        isFetching: false,
        error: false,
    },
    reducers: {
        //GET ALL
        getTransactionStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getTransactionSuccess: (state, action) => {
            state.isFetching = false;
            state.transactions = action.payload;
        },
        getTransactionFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const {
    getTransactionStart,
    getTransactionSuccess,
    getTransactionFailure,
} = transactionSlice.actions;

export default transactionSlice.reducer;
