import React, { useState, useEffect, useRef } from "react";
import { SearchForm } from "../molecules/SearchForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import { Navbar } from "../organism/Navbar";
import Footer from "../organism/Footer";
import Card from "../molecules/Card";
import { search } from "../../redux/ApiCalls/genericCalls";
import { useDispatch, useSelector } from "react-redux";

const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);  
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="top-to-btm">
      {" "}
      {showTopBtn && (
        <FontAwesomeIcon
          icon={faArrowCircleUp}
          className="icon-position icon-style"
          onClick={goToTop}
        />
      )}{" "}
    </div>
  );
};
const Home = () => {
  const [featuredArtisan,setFeaturedArtisan] = useState({});
  const dispatch = useDispatch()
  useEffect(() => {
    // alert(5)
    search(dispatch, '').then(res =>{
      setFeaturedArtisan(res);
     
  })
  }, [])
  const search_data =  useSelector((state) => state?.search_data?.search_data);
  
 
  const searchRef = useRef()
  const handleScrollSwap = () => {
    searchRef.current.scrollIntoView({
      behavior: "smooth",
    })

  }
  return (
    <>
      <div>
        <ScrollToTop />
        <Navbar />
        <section className="header-section">
          <div className="row home-content-row justify-content-center">
            <div className="col-md-8">
              <div className="home-banner-content section-text text-center">
                <h1 className="text-light lh-md">
                  Connecting you to artisans<br /> close by...
                </h1>
              </div>
              <div className="section-form">
                <SearchForm handleScrollSwap={handleScrollSwap} />
              </div>
            </div>
          </div>
        </section>

        <section className="section featured-artisans" ref={searchRef}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center">
                  <h4 className="title">Featured Artisans</h4>
                  <p className="subtitle">Find some of the best artisans in various sectors offering quality and professional services</p>
                  <Card search_data={featuredArtisan} />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Card search_data={search_data} />
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Home;
