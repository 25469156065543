import React, {useEffect, useState} from "react";
import Moment from "react-moment";
// import NumberFormat from "react-number-format";
import {Link, useLocation } from "react-router-dom";
import {getTransaction} from "../../redux/ApiCalls/transactionCalls";
import {useDispatch, useSelector} from "react-redux";



const WalletTable = () => {
  const requestcategory = ["All", "completed", "in-progress", "accepted"];
  const [color, setColor] = useState("");
  const dispatch = useDispatch()
  const location = useLocation();

  const page = location.pathname;

   const [activeMenu, setActiveMenu] = useState()
  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState({})

  const handleOpenModal = (val) => {
    setModal(true);
    setModalData(val)
  }
  const closeOpenModal = () => {
    setModal(false)
  }
  const [transactions, setTransactions] =useState({
    data:[],
    total: 0
});
  useEffect( () => {
    getTransaction(dispatch, "wallet").then(resp=>{
      setTransactions(resp);
    })
  }, [dispatch])
  // const transactions = useSelector((state) => state?.transactions?.transactions);

  return (
    <>
      <div className="div">
        <div className="row d-flex justify-content-between align-item-center">
          <div className='col-8'>
            <h4 className="card-title mt-3 fw-bold" style={{fontSize: 18}}>Wallet Transactions</h4>
          </div>
            <div className="col-2">
              <form className='my-2'>
                <label htmlFor="search">Filter</label>
                <select className='form-select'>
                  {requestcategory.length === 0 ? (
                      <option>loading ....</option>
                  ) : (
                      requestcategory?.map((d) => <option>{d}</option>)
                  )}
                </select>
              </form>
            </div>
        </div>
      </div>
      <table className="table mt-2">
        <thead>
          <tr>
                <th scope="col">Payment Method</th>
                <th scope="col">Amount</th>
                <th scope="col">Date Created</th>
                <th scope="col">status</th>
                {/* <th scope="col">Action</th> */}
          </tr>
        </thead>
        <tbody>
          {transactions?.data?.map((data, i) => (
            <tr id={i} key={i}>
              <td className="td">{data?.payment_method}</td>
              {/*<td className="td">{data.currency}</td>*/}
              <td className="td"> ₦{data?.amount}</td>
              <td className="td">
                <Moment format="D MMM YYYY">{data?.created_at}</Moment>
              </td>
              <td className="td">{data.payment_status ?
                  <span className="btn btn-sm btn-info text-white">Success</span>
                  :
                  <span className="btn btn-sm btn-danger text-white">Failed</span>}
              </td>
              
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        {
            modal &&
            <div className="modal-div">
              <div className="modal-box-container">
                <div className="col-11 col-md-5 table-popup">
                  <header className="d-flex align-items-center justify-content-between">
                    <div>
                      <h4 className="text-grey-900">Market Orders Details</h4>
                    </div>
                    <div className="pointer" onClick={closeOpenModal}>
                      <h5 className="pointer">X</h5>
                      {/*<img src={closeIcon} onClick={closeOpenModal}/>*/}
                    </div>
                  </header>

                  <div className="row d-flex align-items-start justify-content-between mt-5">

                    <div className="text-left">
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Name</span>
                        <span className="text-grey-900">{modalData.name}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Profession</span>
                        <span className="text-grey-900">{modalData.profession}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Price</span>
                        <span className="text-grey-900">{modalData.price}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Duration</span>
                        <span className="text-grey-900">{modalData.duration}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Status</span>
                        <span className="text-grey-900">{modalData.status}</span>
                      </h5>
                    </div>

                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    </>
  );
};

export default WalletTable;
