import React, { useState } from "react";
import { loginFunc } from "../../redux/ApiCalls/authCalls";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/Buttons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router";
import { Navbar } from "../organism/Navbar";



const LoginForm = () => {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordHidden, setPasswordHidden] = useState(true);
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const location = useLocation()
  const [route, setRoute] = useState({ //--> It can be replaced with useRef or localStorage
    to: location.pathname,
    from: location.pathname //--> previous pathname
  });
  // navigate(-1);
  const Submit = (e) => {
    e.preventDefault();

    loginFunc(dispatch, { email, password }).then(resp=>{
      
      if(resp=="User login was successful."){
        // if(route.from!="/")
        // {
          // window.location.href=route.from;
          const body=document.body;
          body.className='';
          body.style='';	
          const element = document.getElementById('exampleModal');			
          element.className="modal fade";
          document.body.style.overflow = 'scroll';
          // window.$('#exampleModal').modal('hide')
          // const element = document.getElementById('exampleModal');			
          // element.modal('hide');
		// 		element.className='modal fade ';
    //     const body=document.body;
    // body.className='';
    // body.style='';	
        // }
        // else{        
        // window.location.href = "/dashboard/index"
        // }
      }
      
       
    });
  };
  const { isFetching } = useSelector(state => state.user)

  return (
    <>
      <form onSubmit={Submit}>
        <div className="row">
          <div className="col-md-12">
            <label htmlFor="email">Email</label>
            <input
              className="form-control"
              type="text"
              placeholder="Email Address"
              style={{ width: "100%" }}
              onChange={({ target: { value } }) => setEmail(value)}
            />
          </div>
        </div>
        <div className="row">

          <div className="col-md-12" style={{ position: "relative" }}>
            <label htmlFor="email">Password</label>
            <FontAwesomeIcon
              icon={faEye} size="1x"
              onClick={() => setPasswordHidden(prevState => !prevState)}
              style={{
                cursor: "pointer", position: "absolute", marginTop: "40px",
                marginLeft: "240px"
              }}
            />
            <input
              className="form-control"
              type={passwordHidden ? "password" : "text"}
              name="password"
              id="password"
              placeholder="Enter password"
              style={{ width: "100%" }}
              onChange={({ target: { value } }) => setPassword(value)}
            />
          </div>
        </div>
        <div className="row my-2">
          <div className="col-md-12">
            <p className="text-end text-danger" data-bs-toggle="modal" data-bs-target="#forgotPasswordModal" data-bs-dismiss="modal"
              aria-label="Close" style={{ fontSize: 14, cursor: "pointer" }}>
              forgot password?
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <Button
                loading={isFetching}
                className="btn btn-danger text-white fw-bold"
                style={{ width: "100%" }}
            >
              Sign In
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default LoginForm;
