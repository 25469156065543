import {publicRequest, userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getProfileFailure,
    getProfileStart,
    getProfileSuccess,
    updateProfileFailure,
    updateProfileStart,
    updateProfileSuccess,
    updatePasswordFailure,
    updatePasswordStart,
    updatePasswordSuccess,
} from "../profileRedux";

//

export const getProfile = async (dispatch) => {
    // alert(5)
    dispatch(getProfileStart());
    try {
        const res = await userRequest.get(`/user/profile`);
        dispatch(getProfileSuccess(res.data.data.user));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getProfileFailure());
        Notification.error(err.response.data.message);
    }
};

export const updateProfile = async (dispatch, data) => {
    // alert(5)
    dispatch(updateProfileStart());
    try {
        const res = await userRequest.post(`/user/profile`, data);
        console.log("data",res.data.data.user )
        dispatch(updateProfileSuccess(res.data.data.user));
        Notification.success(res.data.message);
    } catch (err) {
        dispatch(updateProfileFailure());
        Notification.error(err.response.data.message);
    }
};

export const updatePassword = async (dispatch, data) => {
    dispatch(updatePasswordStart());
    try {
        // const res = await userRequest.post(`/Carts`, Cart);
        const res = await userRequest.post(`user/change-password`, data);
        // console.log("post data", res.data.data)
        dispatch(updatePasswordSuccess(res.data.data));
        Notification.success(res.data.message);
    } catch (err) {
        dispatch(updatePasswordFailure());
        console.log(err)
        Notification.error(err.response.data.message);
    }
};
