import React, {useEffect, useState} from "react";
import Moment from "react-moment";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {getAllProducts, getUserProducts} from "../../redux/ApiCalls/productCalls";

const tableheader = [
  "Name",
  "Profession",
  "Price",
  "Duration",
  "status",
  "Action",
];
const requestcategory = ["All", "completed", "in-progress", "accepted"];

const MarketTable = () => {
  const [color, setColor] = useState("");
  const dispatch = useDispatch()
  const location = useLocation();

  const page = location.pathname;

  const [activeMenu, setActiveMenu] = useState()
  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [products, setProduct] = useState({})

  const handleOpenModal = (val) => {
    setModal(true);
    setModalData(val)
  }
  const closeOpenModal = () => {
    setModal(false)
  }

  useEffect( () => {
    getUserProducts(dispatch).then(resp=>{
      
      setProduct(resp);
    })
  }, [dispatch])
  // const products = useSelector((state) => state?.product?.products);

 
  return (
    <>
      <div className="div">
        <div className="row d-flex justify-content-between align-item-center">
          <div className='col-8'>
            <h4 className="card-title mt-3 fw-bold" style={{fontSize: 18}}>Market Place Records</h4>
          </div>
            {/*<div className="col-2">*/}
            {/*  <form className='my-2'>*/}
            {/*    <label htmlFor="search">Filter</label>*/}
            {/*    <select className='form-select'>*/}
            {/*      {requestcategory.length === 0 ? (*/}
            {/*          <option>loading ....</option>*/}
            {/*      ) : (*/}
            {/*          requestcategory.map((d) => <option>{d}</option>)*/}
            {/*      )}*/}
            {/*    </select>*/}
            {/*  </form>*/}
            {/*</div>*/}
        </div>
      </div>
      <table className="table mt-2">
        <thead>
          <tr>
            <th scope="col">Product Name</th>
            <th scope="col">Quantity</th>
            <th scope="col">Amount</th>
            <th scope="col">Total Amount</th>
            <th scope="col">Transaction Date </th>
            {/* <th scope="col">status</th>
            <th scope="col">Action</th> */}
          </tr>
        </thead>
        <tbody>
        {products?.data?.map((data, i) => (
            <tr id={i} key={i}>
              <td className="td">{data?.product.name}</td>
              <td className="td">{data?.quantity}</td>
              <td className="td"> ₦{data?.product.amount}</td>
              <td className="td"> ₦{data?.transaction?.amount}</td>
              <td className="td">
                <Moment format="D MMM YYYY">{data?.created_at}</Moment>
              </td>
              {/* <td className="td">{data?.is_active ?
                  <span className="btn btn-sm btn-info text-white">Active</span>
                  :
                  <span className="btn btn-sm btn-danger text-white">Not Active</span>}
              </td>
              <td>
                <button className="btn btn-sm btn-primary mt-1" onClick={() => handleOpenModal(data)} id={i} key={i}>View</button> {" "}
                <button className="btn btn-sm btn-danger text-white mt-1" onClick={() => alert("deleted Successfully")}>Delete</button>
              </td> */}
            </tr>
        ))}
        </tbody>
      </table>

      <div>
        {
            modal &&
            <div className="modal-div">
              <div className="modal-box-container">
                <div className="col-11 col-md-6 table-popup" style={{width:"35%"}}>
                  <header className="d-flex align-items-center justify-content-between">
                    <div>
                      <h4 className="text-grey-900">Market Place Details</h4>
                    </div>
                    <div style={{cursor:"pointer"}} className="pointer" onClick={closeOpenModal}>
                      <h5 className="pointer">X</h5>
                      {/*<img src={closeIcon} onClick={closeOpenModal}/>*/}
                    </div>
                  </header>

                  <div className="row d-flex align-items-start justify-content-between mt-5">

                    <div className="text-left">
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Name:</span>
                        <span className="text-grey-900">{modalData.name}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Amount:</span>
                        <span className="text-grey-900">₦{modalData.amount}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Description:</span>
                        <span className="text-grey-900">{modalData.description}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Business Name:</span>
                        <span className="text-grey-900">{modalData?.artisan?.business_name}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Phone No:</span>
                        <span className="text-grey-900">{modalData?.artisan?.phone}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Address:</span>
                        <span className="text-grey-900">{modalData?.artisan?.address}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>ID:</span>
                        <span className="text-grey-900">{modalData?.artisan?.unique_id}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Category:</span>
                        <span className="text-grey-00">{modalData?.category?.name}</span>
                      </h5>
                    </div>

                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    </>
  );
};

export default MarketTable;
