import React, { useState } from "react";
import Card from "../molecules/Card";

const Category = () => {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  const list = [
    "Plumber",
    "driver",
    "teacher",
    "doctor",
    "bricklayer",
    "hairdresser",
    "nurse",
    "chemist",
    "tv repairer",
    "retailer",
    "artist",
    "designer",
    "conductor",
    "typist",
    "secetary",
    "..see more",
  ];

  const handleClick = (e) => {
    //   setShow(!show);
    setShow(true);
    const value = e.target.value;
    setValue(value);
  };
  return (
    <>
      {/* <Navbar /> */}
      <div className="container" style={{ marginTop: 30 }}>
        <div className="row">
          <div className="col-md-12">
            <div className="text-center howitworks">
              <h2 className="fw-bolder">Categories</h2>
              <p className="text-danger">
                search through all our various artisan category
              </p>
            </div>
            <div style={{paddingBottom: 100}}>
              {list.map((d) => (
                <>
                  <button
                    value={d}
                    className="btn btn-danger mx-1 my-2"
                    onClick={handleClick}
                  >
                    {d}
                  </button>
                </>
              ))}
            </div>
            {show === true ? <Card data={value} /> : null}
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default Category;
