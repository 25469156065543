import {publicRequest, userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getSingleWalletFailure,
    getSingleWalletStart,
    getSingleWalletSuccess,
    getWalletFailure,
    getWalletStart,
    getWalletSuccess,
    deleteWalletFailure,
    deleteWalletStart,
    deleteWalletSuccess,
    updateWalletFailure,
    updateWalletStart,
    updateWalletSuccess,
    addWalletFailure,
    addWalletStart,
    addWalletSuccess,
} from "../walletRedux";

//

export const topUpWallet = async (dispatch, Wallet) => {
    dispatch(addWalletStart());
    try {
        // const res = await userRequest.post(`/Wallets`, Wallet);
        const res = await userRequest.post(`user/wallets/topup/paystack`, Wallet);
        // console.log("post data", res.data.data)
        dispatch(addWalletSuccess(res.data.data));
        Notification.success(res.data.message);
        window.location.href = res.data.data.authorization_url;
    } catch (err) {
        dispatch(addWalletFailure());
        console.log(err)
        Notification.error(err.response.data.message);
    }
};

export const getWallet = async (dispatch) => {
    // alert(5)
    dispatch(getWalletStart());
    try {        
        const res = await userRequest.get("/user/wallets");
        dispatch(getWalletSuccess(res.data.data.wallet));
        // Notification.success(res.data.message);
        return res.data.data.wallet;
    } catch (err) {
        dispatch(getWalletFailure());
        Notification.error(err.response.data.message);
    }
};

export const getSingleWallet = async (id, dispatch) => {
    // alert(5)
    dispatch(getSingleWalletStart());
    try {
        const res = await userRequest.get(`/user/Wallets/${id}`);
        dispatch(getSingleWalletSuccess(res.data.data.Wallet));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getSingleWalletFailure());
        Notification.error(err.response.data.message);
    }
};

export const updateWallet = async (id, Wallet, dispatch) => {
    // alert(5)
    dispatch(updateWalletStart());
    try {
        const res = await userRequest.put(`/admin/Wallets/${id}`, Wallet);
        dispatch(updateWalletSuccess(res.data.data));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(updateWalletFailure());
        Notification.error(err.response.data.message);
    }
};
//
export const deleteWalletFunc = async (id, dispatch) => {
    dispatch(deleteWalletStart());
    try {
        const res = await userRequest.delete(`/admin/Wallets/${id}`);
        dispatch(deleteWalletSuccess(id));
        Notification.success(res.data.message);
        window.location.reload();
    } catch (err) {
        dispatch(deleteWalletFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePageChange = async (pageNum, perPage, dispatch) => {
    // alert(5)
    dispatch(getWalletStart());
    try {
        const res = await userRequest.get(`/admin/Wallets?page=${pageNum}&limit=${perPage}`);
        dispatch(getWalletSuccess(res.data.data.Wallets));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getWalletFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePerRowsChange = async (page, perPage, dispatch) => {
    // alert(5)
    dispatch(getWalletStart());
    try {
        const res = await userRequest.get(`/admin/Wallets?page=${page}&limit=${perPage}`);
        dispatch(getWalletSuccess(res.data.data.Wallets));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getWalletFailure());
        Notification.error(err.response.data.message);
    }
};
