import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoneyBills, faMoneyBillTransfer} from "@fortawesome/free-solid-svg-icons";
import WalletTable from "../molecules/walletTable";
import {useDispatch, useSelector} from "react-redux";
import {topUpWallet, getWallet} from "../../redux/ApiCalls/walletCalls";
import Button from "../Buttons";


const Wallet = () => {
    const [amount, setAmount] = useState("");
    const [payModal, setPayModal] = useState(false);
    const [wallet, setWallet] =useState({
        data:[]
    });
    const dispatch = useDispatch()

    useEffect( () => {
        getWallet(dispatch).then(resp=>{
            setWallet(resp);
        })
    }, [dispatch])
    const {isFetching} = useSelector(state=>state?.wallet);
    // const wallet = useSelector((state) => state?.wallet?.wallet);

    const onsubmit = (e) =>{
        e.preventDefault()
        topUpWallet(dispatch, {amount,callbackUrl:window.location.href})
    }

    return (
        <>
            <div className="container-fluid mt-0">
                <div className="row">
                    <div className="col-md-3">
                        <div className="card  mt-5 p-3 card-height">
                            <div className="d-flex justify-content-between lh-lg pd-10">
                                <div
                                    className="border me-3 p-2"
                                    style={{borderRadius: '50%', width: 50, height: 50, textAlign: 'center', background:"#dc3545"}}
                                >
                                    <FontAwesomeIcon icon={faMoneyBills}  className='mx-2 text-white'/>
                                </div>

                                <div>
                                    <p className="mb-5">Total Balance</p>
                                    <h5 className="text-start fw-medium">&#8358; {wallet?.balance}</h5>
                                </div>
                            </div>
                            {/*<h3 className="text-start fw-medium  mt-3 ml-20">&#8358; 4500</h3>*/}
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card  mt-5 p-3 card-height">
                            <div className="d-flex justify-content-between lh-lg pd-10">
                                <div
                                    className="border bg-success me-3 p-2"
                                    style={{borderRadius: '50%', width: 50, height: 50, textAlign: 'center'}}
                                >
                                    <FontAwesomeIcon icon={faMoneyBillTransfer}  className='mx-2 text-white'/>
                                </div>
                                <div>
                                    <p className="mb-5">Bonus Balance</p>
                                    <h5 className="text-start fw-medium">&#8358; {wallet?.bonus_balance}</h5>
                                </div>
                            </div>
                            {/*<h3 className="text-start fw-medium  mt-3 ml-20">&#8358; 250</h3>*/}
                        </div>
                    </div>
                </div>


                <div className="row py-2">
                    <div className="col-md-2">
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <button onClick={()=> setPayModal(true)}
                                        className="btn btn-danger text-white fw-bold py-2"
                                        style={{ width: "100%" }}
                                    >
                                            <span>Top Up Wallet{'  '}</span>
                                    </button>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="card" style={{padding: 30}}>
                            <WalletTable />
                        </div>
                    </div>
                </div>

                <>
                    {payModal &&
                        <div className="modal-div overflow-auto">
                            <div className="modal-box-container">
                                <div className="col-6 col-md-6 table-popup">
                                    <form onSubmit={onsubmit}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label htmlFor="email">Topup Amount</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Enter amount"
                                                    style={{ width: "100%" }}
                                                    onChange={({ target: { value } }) => setAmount(value)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-md-12">
                                                <Button
                                                    loading={isFetching}
                                                    className="btn btn-danger text-white fw-bold"
                                                    style={{ width: "40%" }}
                                                >
                                                    Submit
                                                </Button>

                                                <button
                                                    onClick={()=>setPayModal(false)}
                                                    className="btn btn-danger text-white fw-bold py-2"
                                                    style={{ width: "40%", float:"right" }}
                                                >
                                                        <span>Cancel{'  '}</span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    }
                    </>
            </div>
        </>
    );
};

export default Wallet;
