import React, { useState } from "react";

const tableheader = [
  "Name",
  "Profession",
  "Price",
  "Duration",
  "status",
  "Action",
];
const requestcategory = ["All", "completed", "in-progress", "accepted"];
const details = [
  {
    name: "john doe",
    profession: "plumber",
    price: "20000",
    duration: "3",
    status: "open",
  },
  {
    name: "kemi badmus",
    profession: "tailor",
    price: "1000",
    duration: "2",
    status: "accepted",
  },
  {
    name: "owenna john",
    profession: "coach",
    price: "9000",
    duration: "5",
    status: "completed",
  },
  {
    name: "john doe",
    profession: "plumber",
    price: "20000",
    duration: "3",
    status: "in progress",
  },
];
const RequestTable = () => {
  const [color, setColor] = useState("");

   const [activeMenu, setActiveMenu] = useState()
  const [modal, setModal] = useState(false)
  const [modalData, setModalData] = useState({})

  const handleOpenModal = (val) => {
    setModal(true);
    setModalData(val)
  }
  const closeOpenModal = () => {
    setModal(false)
  }

  return (
    <>
      <div className="div">
        <div className="row d-flex justify-content-between align-item-center">
          <div className='col-8'>
            <h4 className="card-title mt-3 fw-bold" style={{fontSize: 18}}>Request Records</h4>
          </div>
            <div className="col-2">
              <form className='my-2'>
                <label htmlFor="search">Filter</label>
                <select className='form-select'>
                  {requestcategory.length === 0 ? (
                      <option>loading ....</option>
                  ) : (
                      requestcategory.map((d) => <option>{d}</option>)
                  )}
                </select>
              </form>
            </div>
        </div>
      </div>
      <table className="table mt-2">
        <thead>
          <tr>
            {tableheader.map((d) => (
              <>
                <th scope="col">{d}</th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {details.map((data, i) => (
            <tr id={i} key={i}>
              <td className="td">{data.name}</td>
              <td className="td">{data.profession}</td>
              <td className="td">{data.price}</td>
              <td className="td">{data.duration}</td>
              <td className="td">{data.status}</td>
              <td>
                <button className="btn btn-sm btn-primary mt-1" onClick={() => handleOpenModal(data)} id={i} key={i}>View</button> {" "}
                <button className="btn btn-sm btn-danger text-white mt-1" onClick={() => alert("deleted Successfully")}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        {
            modal &&
            <div className="modal-div">
              <div className="modal-box-container">
                <div className="col-11 col-md-5 table-popup">
                  <header className="d-flex align-items-center justify-content-between">
                    <div>
                      <h4 className="text-grey-900">Request Details</h4>
                    </div>
                    <div className="pointer" onClick={closeOpenModal}>
                      <h5 className="pointer">X</h5>
                      {/*<img src={closeIcon} onClick={closeOpenModal}/>*/}
                    </div>
                  </header>

                  <div className="row d-flex align-items-start justify-content-between mt-5">

                    <div className="text-left">
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Name</span>
                        <span className="text-grey-900">{modalData.name}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Profession</span>
                        <span className="text-grey-900">{modalData.profession}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Price</span>
                        <span className="text-grey-900">{modalData.price}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Duration</span>
                        <span className="text-grey-900">{modalData.duration}</span>
                      </h5>

                      <br/>
                      <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                        <span>Status</span>
                        <span className="text-grey-900">{modalData.status}</span>
                      </h5>
                    </div>

                  </div>
                </div>
              </div>
            </div>
        }
      </div>
    </>
  );
};

export default RequestTable;
