import React from "react";
import RequestTable from '../molecules/RequestTable'

const Requests = () => {
  return (
    <>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-md-12">
            <div className="card" style={{padding: 30}}>
              <RequestTable />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Requests;
