import React from "react";
import LoginForm from "../molecules/LoginForm";
import logo from "../assets/jambolologo.png";

const LoginModal = ({closeModal}) => {
 
  // const closeModal = () => {
  //   // const element = document.getElementById('exampleModal');
  //   // element.className='modal fade';
  // };
 
  return (
    <>
      <div      
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content p-0">
            <div className="modal-body p-0" style={{ height: "auto" }}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 bg-dark">
                    <div
                      className="d-flex text-center"
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "100px 0px",
                      }}
                    >
                      <img src={logo} alt="logo" />
                      <p className="text-light">
                        Connecting you to artisans close by...
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6"
                    style={{
                      // padding: "100px 20px",
                      backgroundColor: "#dcdcdc",
                    }}
                  >
                    <div className="text-end p-2">
                      <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        data-bs-dismiss="modal"
                        // onClick={()=>{closeModal(false)}}
                      ></button>
                    </div>
                    <div
                      style={{
                        padding: "60px 20px",
                      }}
                    >
                      <h4 className="text-center fw-bolder">Welcome Back</h4>
                      <p className="text-center" style={{ fontSize: 16 }}>
                        Fill in all blank spaces with the correct credentials
                      </p>
                      <div>
                        <LoginForm />
                      </div>
                      <p className="pt-2 text-center" style={{ fontSize: 14 }}>
                        Dont have an account?{" "}
                        <span style={{cursor:"pointer"}} className="text-danger" data-bs-toggle="modal" data-bs-target="#signupModal" data-bs-dismiss="modal"
                              aria-label="Close">
                          sign up</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginModal;
