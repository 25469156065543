import {userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getSingleProductFailure,
    getSingleProductStart,
    getSingleProductSuccess,
    getProductFailure,
    getProductStart,
    getProductSuccess,
} from "../productRedux";

export const getAllProducts = async (dispatch, name) => {
    // alert(55)
    dispatch(getProductStart());
    try {
        const res = await userRequest.get(name ? `/user/products?filter[name]=${name}` : `/user/products?include=artisan,category`);
        dispatch(getProductSuccess(res.data.data.products));
        // Notification.success(res.data.message);
        return res.data.data.products;
    } catch (err) {
        dispatch(getProductFailure()); 
        // Notification.error(err.response.data.message);
        return "undefined";
    }
};

export const getUserProducts = async (dispatch, name) => {
    // alert(55)
    dispatch(getProductStart());
    try {
        const res = await userRequest.get(name ? `/user/products/user?filter[name]=${name}` : `/user/products/user?include=product,transaction`);
        dispatch(getProductSuccess(res.data.data.products));
        // Notification.success(res.data.message);
        return res.data.data.products;
    } catch (err) {
        dispatch(getProductFailure()); 
        // Notification.error(err.response.data.message);
        return "undefined";
    }
};

export const getSingleProduct = async (dispatch, id) => {
    // alert(5)
    dispatch(getSingleProductStart());
    try {
        const res = await userRequest.get(`/user/products/${id}?include=artisan,category`);
        dispatch(getSingleProductSuccess(res.data.data.product));
        // Notification.success(res.data.message);
        return res.data.data.product;
    } catch (err) {
        dispatch(getSingleProductFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePageChange = async (pageNum, perPage, dispatch) => {
    dispatch(getProductStart());
    try {
        const res = await userRequest.get(`/user/Products?page=${pageNum}&limit=${perPage}`);
        dispatch(getProductSuccess(res.data.data.Products));
    } catch (err) {
        dispatch(getProductFailure());
        Notification.error(err.response.data.message);
    }
};

export const handlePerRowsChange = async (page, perPage, dispatch) => {
    dispatch(getProductStart());
    try {
        const res = await userRequest.get(`/user/Products?page=${page}&limit=${perPage}`);
        dispatch(getProductSuccess(res.data.data.Products));
    } catch (err) {
        dispatch(getProductFailure());
        Notification.error(err.response.data.message);
    }
};
