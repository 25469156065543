import React from "react";

import {Link, Outlet, useLocation } from "react-router-dom";
import {useSelector} from "react-redux";

const style = {
    link: {
        textDecoration: "none",
        color: "#fff",
        fontSize: 16,
        fontWeight: 600,
        lineHeight: 1,
        padding: 10,
    },

    linkSec: {
        textDecoration: "none",
        color: "#dc3545",
        fontSize: 16,
        fontWeight: 400,
        lineHeight: 1,
        padding: 10,
    },
};


const Dashboardnav = () => {
    const location = useLocation();
    const page = location.pathname.split('/')[2];
    const {isFetching, error, currentUser, isSuccess} = useSelector(state=>state.user)
    const {first_name, last_name} = currentUser?.data?.user;

    // console.log("kdjdjdjdjd", currentUser?.data?.user)
    return (
        <>
            <section>
                    <nav style={{paddingLeft:"0px"}} className="navbar navbar-expand-lg navbar-light bg-transparent text-light pt-3">
                        <div className="container-fluid">
                            <button className="navbar-toggler" type="button">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="d-flex justify-content-end">
                                <div className="d-flex">
                                    <p className="text-dark">Welcome, </p> {" "}
                                    <p className="text-danger fw-bolder" style={{marginLeft:"5px"}}>{first_name}{" "}{last_name}</p>
                                </div>

                            </div>
                        </div>
                    </nav>
            </section>
        </>
    );
};

export default Dashboardnav;
