import React, {useEffect, useState} from "react";
import Pics from "../assets/faces3.jpg";
import EditProfileModal from "../organism/EditProfileModal";
import ChangePassword from '../organism/ChangePassword'
import {getProfile, updateProfile, updatePassword} from "../../redux/ApiCalls/profileCalls";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {registerFunc} from "../../redux/ApiCalls/authCalls";
import Button from "../Buttons";
const Profile = () => {
  const dispatch = useDispatch()
  const location = useLocation();
  const [first_name, setFirstname] = useState("");
  const [last_name, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [editState, setEditState] = useState(true);

  useEffect( () => {
    getProfile(dispatch)
  }, [dispatch])
  const profile = useSelector((state) => state?.profile?.profile);
  const { isFetching } = useSelector(state => state.profile)

  const onsubmit = (e) => {
    e.preventDefault()
    updateProfile(dispatch,
        { first_name: first_name ? first_name : profile?.first_name,
          last_name: last_name ? last_name : profile?.last_name,
          email: email ? email:profile?.email }
    )
  }


  return (
    <>
      <div className="container-fluid mt-5">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex justify-content-between align-item-start mb-3">
              <h4 className="fw-bold">Profile</h4>
              <div className="d-flex">
                {/*<button*/}
                {/*  className="btn btn-danger mx-2"*/}
                {/*  data-bs-toggle="modal"*/}
                {/*  data-bs-target="#editprofileModal"*/}
                {/*>*/}
                {/*  Edit Profile*/}
                {/*</button> */}
                <button
                  className="btn btn-dark mx-2"
                  onClick={(e)=> setEditState(false)}
                >
                  Edit Profile
                </button>
                <button
                  className="btn btn-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#changepasswordModal"
                  style={{color:"#FFFFFF"}}
                >
                  change password
                </button>
              </div>
            </div>
            <div
              className="card"
              style={{ height: "auto", marginTop: 10, padding: "20px" }}
            >
              <div className="d-flex" style={{ width: "100%" }}>
                <div
                  className="text-center"
                  // style={{ width: "50%", borderRight: "1px solid #000" }}
                >
                  <img
                    src={profile?.profile_picture ? profile?.profile_picture : Pics}
                    alt="profile-pic"
                    className="img-fluid"
                    style={{ width: 60, height: 60, objectFit:"cover", borderRadius: "50%" }}
                  />
                </div>
                {/*<div style={{ padding: 15 }}>*/}
                {/*  <p className="fw-bold">Name: John doe</p>*/}
                {/*  <p className="fw-bold">Occupation: Plumber</p>*/}
                {/*  <p className="fw-bold">*/}
                {/*    Address: 12 berger road, Lagos Nigeria.*/}
                {/*  </p>*/}
                {/*  <p className="fw-bold">Date of Birth : 30th, December 2000</p>*/}
                {/*</div>*/}
              </div>

              <form onSubmit={onsubmit}>
                <div className="row mt-4">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="Name">First Name</label>
                        <input type="text"
                               defaultValue={profile?.first_name}
                               className="form-control"
                               name="first_name"
                               placeholder="Enter first name"
                               disabled={editState?true:false}
                               onChange={(e) => setFirstname(e.target.value)}
                        />
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="Name">Last Name</label>
                        <input
                            type="text"
                            defaultValue={profile?.last_name}
                           className="form-control"
                            name="last_name"
                            placeholder="enter last name"
                            disabled={editState?true:false}
                            onChange={(e) => setLastname(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
               <div className="row mt-4">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="Name">Email Address</label>
                        <input
                            type="text"
                            defaultValue={profile?.email}
                            placeholder="email"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={true}
                            className="form-control" />
                      </div>

                      {/*<div className="col-md-6">*/}
                      {/*  <label htmlFor="Name">Date of birth</label>*/}
                      {/*  <input type="text" placeholder="30th, December 2000" className="form-control" />*/}
                      {/*</div>*/}
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-6">
                    <Button
                        loading={isFetching}
                        className="btn btn-danger text-white fw-bold"
                        style={{ width: "100%" }}
                        disabled={editState?true:false}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
      {/* <Modals></Modals> */}
      {/*<EditProfileModal />*/}
      <ChangePassword updatePassword={updatePassword} />
    </>
  );
};

export default Profile;
