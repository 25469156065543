import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLinkedin, faFacebook, faTwitter, faInstagram, } from "@fortawesome/free-brands-svg-icons";
import {faPhone } from "@fortawesome/free-solid-svg-icons";
import logo from '../assets/brand/jlogom.png';

const Footer = () => {
  return (
    <>
<footer>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3 col-sm-12">
			<div className="widget text-widget">
				 <div className="footer-logo">
					<img src={logo} alt="footerlogo" />             
				</div>
				<div className="widget-content">
					<p className="mx-2 text-light">Jambolo is an online marketplace for artisans. Find the artisan you need from masons to handymen and locksmiths. Get in touch if you need help.</p>
					<p className="mx-2 text-light phone-numbers"><FontAwesomeIcon icon={faPhone} /> +234 802 233 4556, +234 701 699 0033</p>
				</div>
				<div className="social-icons">
					<span className="item"><a href="0#"><FontAwesomeIcon icon={faInstagram} className="mx-1" /></a></span>
					<span className="item"><a href="0#"><FontAwesomeIcon icon={faTwitter} className="mx-1" /></a></span>
					<span className="item"><a href="0#"><FontAwesomeIcon icon={faFacebook} className="mx-1" /></a></span>
					<span className="item"><a href="0#"><FontAwesomeIcon icon={faLinkedin} className="mx-1" /></a></span>
				</div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 widget footer-list">
            <ul>
              <div className="px-2">
                <h5 className="text-light">Home</h5>
              </div>
              <li>
                  <a href="/aboutus">What is jambolo?</a>
              </li>
              <li>
                <a href="/scope">The scope</a>
              </li>
              <li>
                <a href="/howitworks">How it works</a>
              </li>
              <li>
                <a href="/faq">FAQ</a>
              </li>
            </ul>
          </div>

          <div className="col-md-3 col-sm-12 widget">
            <ul>
              <div className="px-2">
                <h5 className="text-light">About us</h5>
              </div>
              <li>
                <a href="/ourteam">Who we are</a>
              </li>
              <li>
                <a href="/ourteam">Team</a>
              </li>
              <li>
                <a href="/roadmap">Road map</a>
              </li>
            </ul>
          </div>

          <div className="col-md-3 col-sm-12 widget footer-list">
            <ul>
              <div className="px-2">
                <h5 className="text-light">Other links</h5>
              </div>
              <li>
                <a href="/marketplace">Marketplace</a>
              </li>
              <li>
                <a href="/privacy">Privacy policy</a>
              </li>
              <li>
                  <a href="/termscondtion">Terms and condition</a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container-fluid copyright">
        <div className="row">
          <div className="col-md-12">
            <div className="text-center">
              <p className="text-muted">
                &copy; <span><span className="year">{(new Date().getFullYear())}</span> Jambolo, All rights reserved</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
  );
};

export default Footer;
