import {publicRequest, userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getSingleCategoryFailure,
    getSingleCategoryStart,
    getSingleCategorySuccess,
    getCategoryFailure,
    getCategoryStart,
    getCategorySuccess,
    searchFailure,
    searchStart,
    searchSuccess,
    countryFailure,
    countryStart,
    countrySuccess,
    stateFailure,
    stateStart,
    stateSuccess,
    cityFailure,
    cityStart,
    citySuccess,
} from "../genericRedux";


export const getCategories = async (dispatch) => {
    // alert(5)
    dispatch(getCategoryStart());
    try {
        const res = await publicRequest.get("/generic/categories");
        dispatch(getCategorySuccess(res.data.data.categories));
        // Notification.success(res.data.message);
        // console.log(res.data.data.categories)
    } catch (err) {
        dispatch(getCategoryFailure());
        Notification.error(err.response.data.message);
    }
};

export const getSingleCategory = async (id, dispatch) => {
    // alert(5)
    dispatch(getSingleCategoryStart());
    try {
        const res = await publicRequest.get(`/generic/categories/${id}`);
        dispatch(getSingleCategorySuccess(res.data.data.categories));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getSingleCategoryFailure());
        Notification.error(err.response.data.message);
    }
};

export const search = async (dispatch, data) => {
//    alert(5)
    dispatch(searchStart());
    try {
        // const res = await publicRequest.post(`/generic/search`, data);
        const res = await publicRequest.post(`/generic/search?include=state,city`, data);
        // console.log("SEARCHHHHH", res.data?.data?.search_results)
        dispatch(searchSuccess(res.data?.data?.search_results));
        // Notification.success(res.data.message);
        return res.data?.data?.featured_artisan;
    } catch (err) {
        dispatch(searchFailure());
        Notification.error(err.response.data.message);
    }
};

export const getCountries = async (dispatch, id) => {
//    alert(5)
    dispatch(countryStart());
    try {
        const res = await publicRequest.get(`/generic/countries/${id}`);
        dispatch(countrySuccess(res.data?.data?.countries));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(countryFailure());
        Notification.error(err.response.data.message);
    }
};

export const getStates = async (dispatch, id) => {
//    alert(5)
    dispatch(stateStart());
    try {
        const res = await publicRequest.get(`/generic/states/${id}`);
        dispatch(stateSuccess(res.data?.data?.states));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(stateFailure());
        Notification.error(err.response.data.message);
    }
};

export const getCities = async (dispatch, id) => {
//    alert(5)
    dispatch(cityStart());
    try {
        const res = await publicRequest.get(`/generic/cities/${id}`);
        dispatch(citySuccess(res.data?.data?.cities));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(cityFailure());
        Notification.error(err.response.data.message);
    }
};

