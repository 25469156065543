import React from "react";

import {Link, Outlet, useLocation } from "react-router-dom";
import Sidebar from "../organism/Sidenav";
import Dashboardnav from "../organism/Dashboardnav";


const style = {
  link: {
    textDecoration: "none",
    color: "#fff",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1,
    padding: 10,
  },

  linkSec: {
    textDecoration: "none",
    color: "#dc3545",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1,
    padding: 10,
  },
};


const Dashboard = () => {
  const location = useLocation();
  const page = location.pathname.split('/')[2];
  return (
    <>
      <div className="">
        <div className="row">
          <div className="col-md-2">
            <Sidebar/>
          </div>
          <div className="col-md-10">
                <Dashboardnav />
              <Outlet/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
