import {publicRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    getStateFailure,
    getStateStart,
    getStateSuccess,
    getCountriesFailure,
    getCountriesStart,
    getCountriesSuccess,
    getCitiesFailure,
    getCitiesStart,
    getCitiesSuccess,
} from "../genericLocationsRedux";

//


export const getCountries = async (dispatch) => {
    // alert(5)
    dispatch(getCountriesStart());
    try {
        const res = await publicRequest.get(`/generic/countries`);
        dispatch(getCountriesSuccess(res?.data?.data?.countries));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getCountriesFailure());
        Notification.error(err.response.data.message);
    }
};

export const getStates = async (countryId, dispatch) => {
    // alert(4)
    dispatch(getStateStart());
    try {
        const res = await publicRequest.get(`/generic/states/${countryId}`);
        dispatch(getStateSuccess(res?.data?.data?.states));
        // Notification.success(res.data.message);
    } catch (err) {
            dispatch(getStateFailure());
            Notification.error(err.response.data.message);
    }
};

export const getCities = async (stateId, dispatch) => {
    // alert(5)
    dispatch(getCitiesStart());
    try {
        const res = await publicRequest.get(`/generic/cities/${stateId}`);
        dispatch(getCitiesSuccess(res?.data?.data?.cities));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getCitiesFailure());
        Notification.error(err.response.data.message);

    }
};
