import axios from "axios";
import { getUserToken} from './helpers/auth'

const BASE_URL = process.env.REACT_APP_BASE_URL;
const TOKEN = getUserToken();
export const publicRequest = axios.create({
    baseURL: BASE_URL,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
    }
})

// console.log("TTTTOOOOOOKEEEEN", TOKEN)
// console.log("SEEEECCCCCCTOOOKENNNNN", localStorage.getItem("token"))
export const userRequest = axios.create({
    baseURL: BASE_URL,
    // headers: { token: `Bearer ${TOKEN}` },
    headers: {
        'Authorization': `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
        "Accept": "application/json",
    }
});
