import {publicRequest, userRequest} from "../../requestMethod";
import Notification from "../../utils/notification";
import {
    addOrderFailure,
    addOrderStart,
    addOrderSuccess,
    addPayoutFailure,
    addPayoutStart,
    addPayoutSuccess,
    getSingleOrderFailure,
    getSingleOrderStart,
    getSingleOrderSuccess,
    getOrdersFailure,
    getOrdersStart,
    getOrdersSuccess,
} from "../orderRedux";



// export const makeOrder = async (dispatch, data) => {
//     dispatch(addOrderStart());
//     try {
//         const res = await userRequest.post("/user/make-order", data);
//         dispatch(addOrderSuccess(res.data.data.categories));


//         Notification.success(res.data.message);

//     } catch (err) {
//         dispatch(addOrderFailure());
//         Notification.error(err.response.data.message);
//     }
// };


export const makeOrder = async (dispatch, resData) => {
    const {data, artisan_id} = resData
    dispatch(addPayoutStart());
    try {
        const res = await userRequest.post(`/user/orders/pay-callout-fee`, {artisan_id});
        dispatch(addPayoutSuccess(res.data?.data));
        Notification.success(res.data.message);
        if(res.data.message!="Order created successfully")
        {
            if (res.status === 201) {
                dispatch(addOrderStart());
                try {
                    const res = await userRequest.post("/user/orders", resData);
                    dispatch(addOrderSuccess(res.data.data.categories));
                    Notification.success(res.data.message);
                } catch (err) {
                    dispatch(addOrderFailure());
                    Notification.error(err.response.data.message);
                }
            }
        }else{
            return "successful";
        }
    } catch (err) {
        dispatch(addPayoutFailure());
        Notification.error(err.response.data.message);
    }

};


export const payoutCall = async (dispatch, data) => {
    dispatch(addPayoutStart());
    try {
        const res = await userRequest.post(`/user/pay-callout-fee`, data);
        // console.log("SEARCHHHHH", res.data?.data?.search_results)
        dispatch(addPayoutSuccess(res.data?.data));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(addPayoutFailure());
        Notification.error(err.response.data.message);
    }
};

export const getOrders = async (dispatch,artisan_id) => {
    // alert(5)
    dispatch(getOrdersStart());
    try {
        const res = await userRequest.get(`/user/orders?filter[artisan_id]=${artisan_id}&include=user`);
        dispatch(getOrdersSuccess(res.data.data.orders));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getOrdersFailure());
        Notification.error(err.response.data.message);
    }
};

export const getSingleOrder = async (id, dispatch) => {
    // alert(5)
    dispatch(getSingleOrderStart());
    try {
        const res = await userRequest.get(`/user/orders/${id}?include=artisan,user`);
        dispatch(getSingleOrderSuccess(res.data.data.orders));
        // Notification.success(res.data.message);
    } catch (err) {
        dispatch(getSingleOrderFailure());
        Notification.error(err.response.data.message);
    }
};