import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
    name: "cart",
    initialState: {
        cart: null,
        allcarts: [],
        carts: [],
        cartsave: [],
        quantity:0,
        total:0,
        isFetching: false,
        error: false,
    },
    reducers: {
        //GET ALL
        getCartStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getCartSuccess: (state, action) => {
            state.isFetching = false;
            state.allcarts = action.payload;
        },
        getCartFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //GET Single Cart
        getSingleCartStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getSingleCartSuccess: (state, action) => {
            state.isFetching = false;
            state.cart = action.payload;
        },
        getSingleCartFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //DELETE
        deleteCartStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        deleteCartSuccess: (state, action) => {
            state.isFetching = false;
            // state.Carts.splice(
            //   state.Carts.findIndex((item) => item._id === action.payload),
            //   1
            // );
            // state.Carts = {...state.Carts};
        },
        deleteCartFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //UPDATE
        updateCartStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        updateCartSuccess: (state, action) => {
            state.isFetching = false;
            state.carts[
                state.carts.findIndex((item) => item._id === action.payload.id)
                ] = action.payload.cart;
        },
        updateCartFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //add
        addCartStart: (state,action) => {
            state.isFetching = true;
            state.error = false;
        },
        addCartSuccess: (state, action) => {
            state.isFetching = false;
            state.carts.push(action.payload);
        },
        addCartFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

    addCartProduct: (state, action) => {
                state.quantity += 1;
                state.cartsave.push(action.payload);
                state.total += action.payload.amount * action.payload.quantity;
            },

        //Wallet Checkout
        walletCheckoutStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        walletCheckoutSuccess: (state, action) => {
            state.isFetching = false;
            // state.carts.push(action.payload);
        },
        walletCheckoutFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //Wallet Checkout
        paystackCheckoutStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        paystackCheckoutSuccess: (state, action) => {
            state.isFetching = false;
            // state.carts.push(action.payload);
        },
        paystackCheckoutFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const {
    getCartStart,
    getCartSuccess,
    getCartFailure,
    getSingleCartStart,
    getSingleCartSuccess,
    getSingleCartFailure,
    deleteCartStart,
    deleteCartSuccess,
    deleteCartFailure,
    updateCartStart,
    updateCartSuccess,
    updateCartFailure,
    addCartStart,
    addCartSuccess,
    addCartFailure,
    walletCheckoutFailure,
    walletCheckoutStart,
    walletCheckoutSuccess,
    paystackCheckoutFailure,
    paystackCheckoutStart,
    paystackCheckoutSuccess,
    addCartProduct,
} = cartSlice.actions;

export default cartSlice.reducer;
