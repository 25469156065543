import { createSlice } from "@reduxjs/toolkit";

export const genericSlice = createSlice({
    name: "generic",
    initialState: {
        category: null,
        categories: [],
        states: [],
        cities: [],
        countries: [],
        search_data: [],
        isFetching: false,
        error: false,
    },
    reducers: {
        //GET ALL
        getCategoryStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getCategorySuccess: (state, action) => {
            state.isFetching = false;
            state.categories = action.payload;
        },
        getCategoryFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //GET Single Category
        getSingleCategoryStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getSingleCategorySuccess: (state, action) => {
            state.isFetching = false;
            state.category = action.payload;
        },
        getSingleCategoryFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
        //GET SEARCH
        searchStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        searchSuccess: (state, action) => {
            state.isFetching = false;
            state.search_data = action.payload;
        },
        searchFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //GET ALL COUNTRIES
        getCountryStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getCountrySuccess: (state, action) => {
            state.isFetching = false;
            state.countries = action.payload;
        },
        getCountryFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //GET ALL States
        getStateStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getStateSuccess: (state, action) => {
            state.isFetching = false;
            state.states = action.payload;
        },
        getStateFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },

        //GET ALL COUNTRIES
        getCityStart: (state) => {
            state.isFetching = true;
            state.error = false;
        },
        getCitySuccess: (state, action) => {
            state.isFetching = false;
            state.cities = action.payload;
        },
        getCityFailure: (state) => {
            state.isFetching = false;
            state.error = true;
        },
    },
});

export const {
    searchStart,
    searchSuccess,
    searchFailure,
    countryFailure,
    countryStart,
    countrySuccess,

    stateFailure,
    stateStart,
    stateSuccess,

    cityFailure,
    cityStart,
    citySuccess,
    getCategoryStart,
    getCategorySuccess,
    getCategoryFailure,
    getSingleCategoryStart,
    getSingleCategorySuccess,
    getSingleCategoryFailure,
} = genericSlice.actions;

export default genericSlice.reducer;
