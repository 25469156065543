
import "./App.css";
import './fonts/SofiaProBold.ttf'
import Entry from './Entry';
function App() {
  
  return (
    <div className="App">
      <Entry/>
    </div>
  );
}

export default App;
