import React, { useState } from "react";
import Pic from "../assets/faces3.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { makeOrder } from "../../redux/ApiCalls/orderCalls";
// import LoginModal from "../organism/LoginModal";
import Button from "../Buttons";
import {getArtisan} from "../../redux/ApiCalls/transactionCalls";

 
const Card = ({ data, search_data }) => {
  const cardList = ["Abdulganiyu Bosun", "Madu Emma", "Tunji Adeleke", "Peter Abejide", "James Connor", "Segun Ajibade"];
  const dispatch = useDispatch()
  const { currentUser } = useSelector(state => state.user)
  const { isFetching } = useSelector(state => state.order_data)
  const [artisan_id, setArtisanId] = useState(null);
  const [artisan_status, setArtisanStatus] = useState(null);
  const [location, setLocation] = useState(null);
  const [orderPopup, setOrderPopup] = useState(false)
  const [artisandetails, setArtisandetails] = useState(false)
  const [oderderBusName, setOrderBusName] = useState(null);

  const [modalData, setModalData] = useState({})
  const orderFunc = (f,artisan_id, is_active, location) => {
    
    makeOrder(dispatch, { artisan_id, is_active, location }).then(resp=>{
      if(resp=="successful"){
        setArtisanStatus(f?.is_active);
        setOrderBusName(f?.business_name);
        setModalData(f);
        setLocation(f?.address);
        setArtisandetails(true);
      }
    })
    setTimeout(function () {
      setOrderPopup(false)
    }, 2000);
    document.body.style.overflow = 'scroll';
  }
  const closeOpenModal = () => {
    setArtisandetails(false)
    document.body.style.overflow = 'scroll';
  }

  const confirmOrderPopupFunc = (f) => {
    getArtisan().then(trans=>{
      setModalData(f);
      
      if(trans==null){
        setOrderPopup(true);
        setArtisanId(f?.id);
      }
      data=Object.values(trans);
     const isfound= data.some(el => {
       
       if(el.artisan.city_id==f.city_id)
       {        
        return true;
      }
      else{
        return false;
      }
      });
      
      if(isfound)
      {
        setArtisanStatus(f?.is_active);
        setOrderBusName(f?.business_name);
        setModalData(f);
        setLocation(f?.address);
        setArtisandetails(true);
      }
      else{        
        setOrderPopup(true);
        setArtisanId(f?.id);
      }


    
    })
    
    document.body.style.overflow = 'hidden';
  }

  const hide = () => {
    setOrderPopup(false)
    document.body.style.overflow = 'scroll';
  }

  return (
    <>
      <div className="row">

        <h4 className="lh-lg fw-bolder">{data}</h4>
        {
          search_data?.data?.length > 0
            ?
            <>
              {search_data?.data?.map((f, i) => (
                <div className="col-md-4" key={f?.id}>
                  <div className="card list-item" key={f?.id}>
                    <div className="list-image">
                      <img src={f.logo==null?Pic: f.logo} alt={i} />
                      <div className="image-overlay"></div>
                      <span style={{ fontSize: "10px" }} className="list-sector">{f?.business_name}</span>
                    </div>
                    <div className="list-content">
                      <div className="content-wrap">
                        <p className="list-title">{f?.first_name}{" "} {f?.last_name}</p>
                        {/*<p className="list-desc">Professional plumber/pipes cleaner available always.</p>*/}
                        <p className="list-desc">{f?.address ? f?.address : "N/A"}</p>
                        <p className="list-desc">{f?.business_name ? f?.business_name : "N/A"}</p>

                        <div className='list-meta'>
                          <div className="meta-item">
                            <span><FontAwesomeIcon icon={faLocationDot} /></span>
                            <span className="mx-2 my-0">{f?.state?.name} {","} {f?.city?.name}</span>
                          </div>
                          <div className="meta-item">
                            <span><FontAwesomeIcon icon={faPhone} /></span>
                            {/*<span className="mx-2">+234 801 xxx xxxx</span>*/}
                            <span className="mx-2">
                              

                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="list-footer">
                      {
                        currentUser?.data?.user
                          ?
                          <button key={f?.id}
                            // onClick={() => orderFunc(f?.id, f?.is_active ? "1" : "0")} 
                            onClick={(e) => {
                              confirmOrderPopupFunc(f);
                              
                            }}

                            style={{ fontSize: "14px" }}
                            type="button"
                            className="btn btn-danger btn-sm text-white mx-2 my-2" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="500 Naira Charges Apply">
                            <span>Order Now{'  '}</span>
                          </button>
                          :
                          <button className="btn btn-danger btn-sm text-white mx-2 my-2" data-bs-toggle="tooltip" data-bs-placement="top"
                            title="Please login before proceed" style={{ fontSize: 14 }}>
                            <span className="text-white" style={{ cursor: "pointer" }}
                              data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-dismiss="modal"
                              aria-label="Close">
                              Order Now
                            </span>
                          </button>

                      }
                    </div>
                  </div>
                </div>
              ))}
            </>
            :
            // No Record Found
            <h2 style={{ textAlign: "center" }}></h2>
        }
      </div>

      <div>
        {orderPopup && (
          <div className="modal-div overflow-auto">
            <div className="modal-box-container">
              <div className="col-6 col-md-6 table-popup">
                <div className="row">
                  <div className="col-md-12">
                    <label htmlFor="email">Are you sure you want to order for <b>{oderderBusName}</b> product?</label>
                    <p>Note that 500 Naira will be deducted from you wallet balance for this action</p>
 
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12">

                    {/*<button*/}
                    {/*  onClick={() => orderFunc(artisan_id, artisan_status ? "1" : "0", location)}*/}
                    {/*  style={{ fontSize: "14px", width: "35%" }} type="button" className="btn btn-primary btn-sm text-white mx-2 my-2" data-bs-toggle="tooltip" data-bs-placement="top"*/}
                    {/*  title="500 Naira Charges Apply">*/}
                    {/*  {isFetching ? (*/}
                    {/*    <Spinner />*/}
                    {/*  ) : (*/}
                    {/*    <span>Order Now{'  '}</span>*/}
                    {/*  )}*/}
                    {/*</button>*/}
                    <Button
                        onClick={() => orderFunc(modalData,artisan_id, artisan_status ? "1" : "0", location)}
                        loading={isFetching}
                        className="btn btn-danger text-white fw-bold"
                        style={{ width: "35%" }}
                    >
                      Order Now
                    </Button>

                    <button
                      onClick={() => hide(false)}
                      className="btn btn-danger btn-sm text-white mx-2 my-2"
                      style={{ width: "35%", float: "right" }}
                    >
                      <span>No{'  '}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

{artisandetails && (
          <div className="modal-div">
          <div className="modal-box-container">
            <div className="col-11 col-md-6 table-popup" style={{width:"35%"}}>
              <header className="d-flex align-items-center justify-content-between">
                <div>
                  <h4 className="text-grey-900">Artisan Details</h4>
                </div>
                <div style={{cursor:"pointer"}} className="pointer" onClick={closeOpenModal}>
                  <h5 className="pointer">X</h5>
                  {/*<img src={closeIcon} onClick={closeOpenModal}/>*/}
                </div>
              </header>

              <div className="row d-flex align-items-start justify-content-between mt-5">

                <div className="text-left">
                  <img src={modalData.logo} style={{width: '30%', height: '30%'}}/>
                  <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                    <span>Name:</span>
                    <span className="text-grey-900">{modalData.first_name+' '+modalData.last_name}</span>
                  </h5>

                  <br/>
                  <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                    <span>Business Name:</span>
                    <span className="text-grey-900">{modalData.business_name}</span>
                  </h5>

                  <br/>
                  <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                    <span>Phone No:</span>
                    <span className="text-grey-900">{modalData.phone}</span>
                  </h5>

                  <br/>
                  <h5 className="text-grey-600 d-flex align-items-center justify-content-between mbb-2">
                    <span>Address:</span>
                    <span className="text-grey-900">{modalData.address}</span>
                  </h5>

                  
                </div>

              </div>
            </div>
          </div>
        </div>
        )}
      </div>
      {/* <LoginModal /> */}
    </>
  );
};

export default Card;
